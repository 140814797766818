import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";

export const journalApi = createApi({
	reducerPath: 'journalApi',
	baseQuery: GlobalBaseQuery,
	tagTypes: ['Journal'],
	endpoints: build => ({
		getCourseStudents: build.query({
			query: studentsData => ({
				url: `admin/scores/course-students-scores?course_id=${studentsData?.student_id}&point=${studentsData?.student_point}&student_name=${studentsData?.student_name}&city=${studentsData?.student_city}&student_course_status_id=${studentsData?.student_status}`,
				headers: { Accept: 'application/json' },
			}),
			providesTags: result => providesList(result?.data, 'Journal'),
		}),
		getStudentScores: build.query({
			query: students => ({
				url: `admin/scores/student-course-progress-with-scores?course_id=${students.id}&user_id=${students.studentId}`,
				// headers: { Accept: "application/json" },
			}),
			providesTags: result =>
				providesList(
					result?.student_topics_categories_lessons?.data,
					'Journal'
				),
		}),
		addScore: build.mutation({
			query: body => ({
				url: 'admin/scores',
				method: 'POST',
				headers: { Accept: 'application/json' },
				body: body,
			}),
			invalidatesTags: ['Journal'],
		}),
		addComment: build.mutation({
			query: body => ({
				url: 'comments',
				method: 'POST',
				headers: { Accept: 'application/json' },
				body: body,
			}),
			invalidatesTags: ['Journal'],
		}),
		addExtraTimeCategory: build.mutation({
			query: body => ({
				url: 'admin/extend-student-topic-category-time',
				method: 'POST',
				headers: { Accept: 'application/json' },
				body: body,
			}),
			invalidatesTags: ['Journal'],
		}),
		setStudentWorkStatus: build.mutation({
			query: workId => ({
				url: `student-works/status/${workId}/1`,
				method: 'PATCH',
				headers: { Accept: 'application/json' },
				// body: body,
			}),
			invalidatesTags: ['Journal'],
		}),
		changeCoin: build.mutation({
			query: (body) => ({
				url: `admin/conversion/define-rate`,
				method: 'POST',
				body: body,
				headers: { Accept: 'application/json' },
			}),
			invalidatesTags: ['Journal'],
		})
	}),
})
export const {
	useGetCourseStudentsQuery,
	useGetStudentScoresQuery,
	useAddScoreMutation,
	useAddCommentMutation,
	useAddExtraTimeCategoryMutation,
	useSetStudentWorkStatusMutation,
	useChangeCoinMutation,
} = journalApi;
