import { createSlice } from '@reduxjs/toolkit'

const JournalSlice = createSlice({
	name: 'journal',
	initialState: {
		popUps: {
			drawer: false,
			addScoreModal: false,
			addExtraTimeModal: false
		},
		addScoreData: {
			id: '',
			point: 0,
			max_point: 0,
			lesson: ''
		},
		filterData: {
			student_name: '',
			student_point: 0,
			student_city: '',
			student_status: '3'
		},
		addCommentData: {
			content: '',
		},
		addExtraTimeCategoryData: {
			add_category_time: 0,
			student_topic_category_id: '',
		},
		lesson: {},
	},
	reducers: {
		setJournalValues: (state, action) => {
			const { key, value } = action.payload
			state[key] = value
		},
		setJournalPopUPValues: (state, action) => {
			const { key, value } = action.payload
			state.popUps[key] = value
		},
		setAddScoreDataValues: (state, action) => {
			const { key, value } = action.payload
			state.addScoreData[key] = value
		},
		setFilterDataValues: (state, action) => {
			const { key, value } = action.payload
			state.filterData[key] = value
		},
		setAddCommentDataValues: (state, action) => {
			const { key, value } = action.payload
			state.addCommentData[key] = value
		},
		setAddExtraTimeCategoryDataValues: (state, action) => {
			const { key, value } = action.payload
			state.addExtraTimeCategoryData[key] = value
		},
	},
	extraReducers: builder => {},
})

export const {
	setJournalValues,
	setJournalPopUPValues,
	setAddScoreDataValues,
	setFilterDataValues,
	setAddCommentDataValues,
	setAddExtraTimeCategoryDataValues,
} = JournalSlice.actions

export default JournalSlice.reducer
