import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import GlobalBaseQuery, { providesList } from "./api";
// import { setLesson } from "../reducers/purchases";
import GlobalBaseQuery, { providesList } from "../api";
import { topicsApi } from "./topicsApi";

export const lessonsApi = createApi({
  reducerPath: "lessonsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Lessons"],
  endpoints: (build) => ({
    getTopicLessons: build.query({
      query: ({ id, role }) => `${role}/topic/${id}/lessons`,
      providesTags: (result) => providesList(result?.data, "Lessons"),
      headers: { Accept: "application/json" },
    }),

    getCourseLessons: build.query({
      query: (courseId) => `/admin/course/lessons/${courseId}`,
      providesTags: (result) => providesList(result?.data, "Lessons"),
      headers: { Accept: "application/json" },
    }),
    getLessonById: build.query({
      query: (id) => ({
        url: `admin/lesson/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Lessons"),
    }),
    addLesson: build.mutation({
      query: (body) => ({
        url: `lesson/v1`,
        method: `POST`,
        redirect: `follow`,
        body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Lessons"],
    }),
    deleteLesson: build.mutation({
      query: (id) => ({
        url: `lesson/${id}`,
        method: `DELETE`,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Lessons"],
    }),
    editLesson: build.mutation({
      query: ({ id, body }) => ({
        url: `lesson/${id}`,
        method: "POST",
        body: body,
        redirect: "follow",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Lessons"],
    }),
    reorderLessons: build.mutation({
      query: ({ body }) => ({
        url: `update-lesson-order`,
        method: "PUT",
        body: body,
        redirect: "follow",
        accept: "application/json",
      }),
      async onQueryStarted(
        { newOrder, courseId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          topicsApi.util.updateQueryData(
            "getCourseTopics",
            courseId,
            (draft) => {
              draft.course_topics_lessons.topics = newOrder;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    getCourseTests: build.query({
      query: ( course_name ) => ({
        url: `admin/think-exam/group-tests?course_external_id=${course_name}`,
        headers: { Accept: "application/json" },
      }),
      // providesTags: (result) => providesList(result?.data, "Lessons"),
      // headers: { Accept: "application/json" },
    }),
  }),
});

export const {
  useAddLessonMutation,
  useDeleteLessonMutation,
  useEditLessonMutation,
  useGetLessonByIdQuery,
  useGetCourseLessonsQuery,
  useReorderLessonsMutation,
  useGetCourseTestsQuery,
  useLazyGetCourseTestsQuery,
} = lessonsApi;
