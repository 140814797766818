import React from "react";
import CustomFormModal from "../../../Independents/CustomFormModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddTopicDataValue,
  setTopicsPopUpValues,
  setTopicsValues,
} from "../../../../Services/Admin/Course/Topics/TopicsSlice";
import { TextField } from "@mui/material";
import {
  topicsApi,
  useAddTopicMutation,
} from "../../../../api/Admin/topicsApi";

const AddTopicModal = ({ courseId }) => {
  const [addTopic, { isLoading }] = useAddTopicMutation();
  const {
    popUps: { addModal },
    addTopicData: { topic_name },
  } = useSelector((store) => store.topicsAdmin);
  const dispatch = useDispatch();

  const handleAddTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("course_id", courseId);
      formData.append("topic_name", topic_name);
      await addTopic(formData).unwrap();
      dispatch(
        setTopicsValues({
          key: "addTopicData",
          value: {
            topic_name: "",
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomFormModal
      title={"Новый топик"}
      maxWidth="xs"
      open={addModal}
      onClose={() =>
        dispatch(setTopicsPopUpValues({ key: "addModal", value: false }))
      }
      onSave={handleAddTopic}
      saveLoading={isLoading}
      disableSaveButton={topic_name === ""}
    >
      <TextField
        variant="outlined"
        size="medium"
        label="Название"
        fullWidth
        value={topic_name}
        onChange={(e) =>
          dispatch(
            setAddTopicDataValue({ key: "topic_name", value: e.target.value })
          )
        }
      />
    </CustomFormModal>
  );
};

export default AddTopicModal;
