import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getAllUsers: build.query({
      query: ({ page, search }) => ({
        url: `getAllUsers?page=${page}&&search=${search}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Users"),
    }),
    editUserRole: build.mutation({
      query: ({ id, role, body }) => ({
        url: `updateRoleUser/${id}/${role}`,
        method: "POST",
        body: body,
        redirect: "follow",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});
export const { useGetAllUsersQuery, useEditUserRoleMutation } = usersApi;
