import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomFormModal from "../../../../Independents/CustomFormModal";
import {
  setAddCategoryDataValue,
  setCategoriesPopUpValues,
  setCategoriesValues,
} from "../../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import { Grid, TextField } from "@mui/material";
import { useAddCategoryMutation } from "../../../../../api/Admin/topicCategoriesApi";
import { topicsApi } from "../../../../../api/Admin/topicsApi";

const AddCategoryModal = () => {
  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const {
    popUps: { addModal },
    addCategoryData: { name, time, topic_id },
    addCategoryData,
  } = useSelector((store) => store.categoriesAdmin);
  const dispatch = useDispatch();

  const handleAddCategory = async () => {
    try {
      let body = {
        topic_id,
        name,
        time,
      };
      await addCategory(body).unwrap();
      dispatch(
        setCategoriesValues({
          key: "addCategoryData",
          value: {
            topic_id: topic_id,
            name: "",
            time: 0,
          },
        })
      );
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomFormModal
      open={addModal}
      onClose={() => {
        dispatch(setCategoriesPopUpValues({ key: "addModal", value: false }));
        dispatch(
          setCategoriesValues({
            key: "addCategoryData",
            value: {
              topic_id: "",
              name: "",
              time: 0,
            },
          })
        );
      }}
      onSave={handleAddCategory}
      title={"Категория"}
      saveLoading={isLoading}
      disableSaveButton={name.trim() === "" || time == 0}
    >
      <Grid container spacing={"20px"}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            label="Название"
            size="medium"
            fullWidth
            value={name}
            onChange={(e) =>
              dispatch(
                setAddCategoryDataValue({ key: "name", value: e.target.value })
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            size="medium"
            type="number"
            fullWidth
            inputProps={{ min: "0", max: "999" }}
            value={time}
            onChange={(e) =>
              dispatch(
                setAddCategoryDataValue({ key: "time", value: e.target.value })
              )
            }
          />
        </Grid>
      </Grid>
    </CustomFormModal>
  );
};

export default AddCategoryModal;
