// import * as React from "react";

import {
  AnalyticsOutlined,
  BorderColorOutlined,
  CheckRounded,
  CloseOutlined,
  Delete,
  ExpandMore,
  LockOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Switch,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getDifference } from "../../../utils/TimeFormats";

export default function SubscriberInfoDrawer(props) {
  const { open, onClose, drawerData, changeIdx, openDeleteModal } = props;
  const [data, setData] = useState({ name: "hello" });
  // const [deleteModal, setDeleteModal] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [addMentorModal, setAddMentorModal] = useState(false);
  // const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleOpenHideModal = () => setHideModal(true);
  const handleOpenAddMentorModal = () => setAddMentorModal(true);
  const handleClose = () => {
    setHideModal(false);
    setAddMentorModal(false);
  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <Box
          sx={{
            width: { xs: "100%", sm: "550px" },
            height: "100vh",
            backgroundColor: "#F4F4F5",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <CloseOutlined
              sx={{ color: "#262626", cursor: "pointer" }}
              onClick={onClose}
            />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#737373",
              }}
            >
              Инфо о подписке
            </Typography>
          </Box>
          <Divider
            sx={{ backgroundColor: "#E5E7EB", width: "100%", height: "2px" }}
          />
          <Box sx={{ padding: "16px 24px" }}>
            <Box
              sx={{
                padding: "20px",
                borderRadius: "12px",
                backgroundColor: "white",
                mb: "40px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
               {drawerData.photo ? <img
                  src={`${process.env.REACT_APP_ASSTES_URL}storage/${drawerData.photo}`}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "100px",
                  }}
                />:<Box sx={{width:"60px",height:"60px",borderRadius:"100px",bgcolor:"#D1D5DB",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Typography sx={{fontWeight:"600",fontSize:"18px",color:"#525252"}}>{drawerData.name?.charAt(0) + "" + drawerData.surname?.charAt(0) }</Typography></Box>}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#262626",
                    }}
                  >
                    {drawerData?.name} {drawerData?.surname}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#737373",
                    }}
                  >
                    Телефон:{" "}
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#262626",
                      }}
                    >
                      {" "}
                      {drawerData?.phone}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "600", mb: "16px" }}
              >
                Подписка
              </Typography>
              <Accordion sx={{ padding: "4px 20px", borderRadius: "12px" }}>
                <AccordionSummary
                  // expandIcon={<ExpandMore color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    padding: "0px",
                    margin: "0px !important",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "12px" }}>
                      <img
                        src={
                          drawerData?.course?.logo?.includes("storage")
                            ? `${process.env.REACT_APP_ASSTES_URL}${drawerData?.course?.logo}`
                            : `${process.env.REACT_APP_ASSTES_URL}/storage/${drawerData?.course?.logo}`
                        }
                        style={{ width: "52px", height: "52px" }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#1F2937",
                          }}
                        >
                          {drawerData?.course?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: getDifference(drawerData?.subscription?.deleted_at).finished ?"red" :"#3B82F6",
                          }}
                        >
                          {drawerData?.subscription?.name} - {getDifference(drawerData?.subscription?.deleted_at).finished ? "закончился" :getDifference(drawerData?.subscription?.deleted_at).month > 0
                              ? `${getDifference(drawerData?.subscription?.deleted_at).month} мес`: null}{" "}
                            {getDifference(drawerData?.subscription?.deleted_at).day > 0
                              ? `${getDifference(drawerData?.subscription?.deleted_at).day} дней`
                              : null}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#1F2937",
                        }}
                      >
                        {new Date(
                          drawerData?.subscription?.created_at
                        )?.toLocaleDateString("ru", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#A3A3A3",
                        }}
                      >
                        {Math.trunc(drawerData?.subscription?.price)} c
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box sx={{display:"flex",alignItems:"center",gap:"16px"}}><WorkspacePremiumOutlined/> <Typography sx={{fontWeight:"500",fontSize:"16px",lineHeight:"24px"}}>Сертификаты об окончании</Typography></Box> */}
                </AccordionSummary>
                <AccordionDetails>
                  {drawerData?.subscription?.description?.map((elem, ix) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: "16px", mb: "4px" }}
                        key={ix}
                      >
                        <CheckRounded sx={{ fontSize: "20px" }} />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#262626",
                          }}
                        >
                          {elem}
                        </Typography>
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}} >
            <Link to={`/admin/mentors/edit/${drawerData?.id}`}>
            <Button variant="contained" startIcon={<BorderColorOutlined/>}>Изменить</Button>
            </Link>
            <Button variant="text" color="error"  startIcon={<Delete/>}>Удалить</Button>
           </Box>
          <Box sx={{mt:"28px",mb:"28px"}}>
            <Box sx={{padding:"12px 20px",backgroundColor:"white",borderRadius:"12px",display:"flex",justifyContent:"space-between",alignItems:"center",mb:"8px"}}>
                <Box sx={{display:"flex",alignItems:"center",gap:"16px"}}>
                    <LockOutlined/>
                    <Box>
                        <Typography sx={{fontWeight:"500",fontSize:"16px",lineHeight:"24px"}} >Заблокировать аккаунт</Typography>
                        <Typography sx={{fontWeight:"400",fontSize:"14px",lineHeight:"20px",color:"#737373"}} >Полностью блокирует действия студента</Typography>
                    </Box>
                </Box>  
                    <Switch/>
            </Box>
            <Accordion sx={{padding:"8px 4px",borderRadius:"12px",mb:"8px"}}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{display:"flex",alignItems:"center",gap:"16px"}}><AnalyticsOutlined/> <Typography sx={{fontWeight:"500",fontSize:"16px",lineHeight:"24px"}}>Посмотреть активность 1</Typography></Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{padding:"8px 4px",borderRadius:"12px"}}>
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{display:"flex",alignItems:"center",gap:"16px"}}><WorkspacePremiumOutlined/> <Typography sx={{fontWeight:"500",fontSize:"16px",lineHeight:"24px"}}>Сертификаты об окончании</Typography></Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
          </Box>
     */}
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
