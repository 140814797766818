export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = (duration % 3600) % 60;
  return hours.toFixed(0).padStart(2, "0") != "00"
    ? `${hours.toFixed(0).padStart(2, "0")}:${minutes
        .toFixed(0)
        .padStart(2, "0")}:${seconds.toFixed(0).padStart(2, "0")}`
    : `${minutes.toFixed(0).padStart(2, "0")}:${seconds
        .toFixed(0)
        .padStart(2, "0")}`;
};

export const getDifference = (date) => {
  // Current date
  const currentDate = new Date();
  // Specific date to compare
  const specificDate = new Date(date);
  if (currentDate > specificDate) {
    return { finished: true };
  }
  // Calculate the difference in milliseconds
  const timeDiff = specificDate.getTime() - currentDate.getTime();
  // Calculate the difference in days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  // Calculate the difference in months and days
  const monthsDiff = Math.floor(daysDiff / 30);
  const remainingDays = daysDiff % 30;
  // Return the difference as a string
  return { month: monthsDiff, day: remainingDays };
};

export function formatNumber(number) {
  // Round to one decimal place
  let roundedNumber = number.toFixed(1);

  // Remove trailing zero if it exists
  if (roundedNumber.endsWith(".0")) {
    roundedNumber = roundedNumber.slice(0, -2);
  }

  return roundedNumber;
}
