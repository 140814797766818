import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";
// import GlobalBaseQuery, { providesList } from "";

export const topicsApi = createApi({
  reducerPath: "topicsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Topics"],
  endpoints: (build) => ({
    getCourseTopics: build.query({
      query: (id) => ({
        url: `admin/course/${id}/topics/v1`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data?.topics, "Topics"),
    }),
    getCourseTopicsStudy: build.query({
      query: ({ id, role }) => ({
        url: `${role}/course/${id}/topics`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Topics"),
    }),
    addTopic: build.mutation({
      query: (body) => ({
        url: `topic`,
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Topics"],
    }),
    deleteTopic: build.mutation({
      query: (id) => ({
        url: `topic/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Topics"],
    }),
    editTopic: build.mutation({
      query: ({ id, body }) => ({
        url: `topic/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Topics"],
    }),
  }),
});

export const {
  useGetCourseTopicsQuery,
  useAddTopicMutation,
  useDeleteTopicMutation,
  useEditTopicMutation,
  useLazyGetCourseTopicsQuery,
  useGetCourseTopicsStudyQuery,
} = topicsApi;
