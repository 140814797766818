import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "editMentor",
  initialState: {
    editData: {
      id: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      gender: "",
      city: "",
      date_of_birth: "",
      photo: "",
      photoFile: "",
      description: "",
      position: "",
      userSkills: [],
    },
  },
  reducers: {
    handleChangeMentor(state, action) {
      const { name, value } = action.payload;
      state.editData[name] = value;
    },
    setEditData(state, action) {
      const data = action.payload;
      state.editData.id = data.id;
      state.editData.name = data.name;
      state.editData.surname = data.surname;
      state.editData.email = data.email;
      state.editData.phone = data.phone;
      state.editData.gender = data.gender;
      state.editData.city = data.city;
      state.editData.date_of_birth = data.date_of_birth;
      state.editData.photo = data.photo;
      state.editData.userSkills =
        data?.user_skills?.length > 0 ? data?.user_skills : [];
      state.editData.description = data.description;
      state.editData.position = data?.position?.length > 0 ? data.position : "";
    },
    // setToDefaultValue(state) {
    //   state.editData.name ="";
    //   state.editData.surname = "";
    //   state.editData.email = "";
    //   state.editData.phone = "";
    //   state.editData.gender = "";
    //   state.editData.city = "";
    //   state.editData.date_of_birth = "";
    //   state.editData.photo = "";
    // },
  },
});

export const { handleChangeMentor, setEditData, setToDefaultValue } =
  slice.actions;
export default slice.reducer;
