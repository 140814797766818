import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";

export const studentsApi = createApi({
  reducerPath: "studentsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Students", "StudentBalnce"],
  endpoints: (build) => ({
    getStudents: build.query({
      query: ({ page, search }) => ({
        url: `getStudents?page=${page}&&search=${search}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Students"),
    }),
    getStudentsSubscribers: build.query({
      query: ({ page, search }) => ({
        url: `getStudents/subscription?page=${page}&&search=${search}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Students"),
    }),
    getStudentBalance: build.query({
      query: (id) => ({
        url: `admin/student-balance/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "StudentBalnce"),
    }),
    topUpStudentBalance: build.mutation({
      query: ({ id, body }) => ({
        url: `admin/student-balance/${id}`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["StudentBalnce"],
    }),
    blockUnblockStudent: build.mutation({
      query: (id) => ({
        url: `user/${id}/block`,
        method: "PUT",
        redirect: "follow",
      }),
      invalidatesTags: ["Students"],
    }),
  }),
});
export const {
  useGetStudentsQuery,
  useGetStudentBalanceQuery,
  useLazyGetStudentsQuery,
  useGetStudentsSubscribersQuery,
  useBlockUnblockStudentMutation,
  useTopUpStudentBalanceMutation,
} = studentsApi;
