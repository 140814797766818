import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "purchases",
  initialState: {
    purchases: {},
    topics: {},
    activeLesson: {
      // type: "quiz",
      // name: "Quiz 1",
    },
  },
  reducers: {
    // handleChangeMentor(state, action) {
    //   const { name, value } = action.payload;
    //   state.editData[name] = value;
    // },
    setPurchase(state, action) {
      const data = action.payload;
      state.purchases = data;
    },
    setTopics(state, action) {
      const data = action.payload;
      state.topics = data;
    },
    setLesson(state, action) {
      state.activeLesson = action.payload;
    },
  },
});

export const { setPurchase, setTopics, setLesson } = slice.actions;
export default slice.reducer;
