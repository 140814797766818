import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setEditData } from "../reducers/editCourse";

export const coursesApi = createApi({
  reducerPath: "coursesApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Courses", "Mentors"],
  endpoints: (build) => ({
    getCourses: build.query({
      query: ({role,page}) => ({
        url: `${role}/course${role == "admin" ? `?page=${page}` : ""}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Courses"),
    }),
    getCourseById: build.query({
      query: ({ id, role }) => `${role}/course/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const d = await queryFulfilled;
          if (d.data) {
            dispatch(setEditData(d.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getCourseMentors: build.query({
      query: ({ id, role }) => ({
        url: `${role}/teacherByCourse/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Mentors"),
    }),
    addCourse: build.mutation({
      query: (body) => ({
        url: `course`,
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: ["Courses"],
    }),
    deleteCourse: build.mutation({
      query: (id) => ({
        url: `course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Courses"],
    }),
    editCourse: build.mutation({
      query: ({ id, body }) => ({
        url: `course/${id}`,
        method: "POST",
        body: body,
        redirect: "follow",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Courses"],
    }),
    addMentorsToCourse: build.mutation({
      query: ({ id, body }) => ({
        url: `courses/${id}/add-teachers`,
        method: "POST",
        body: body,
        redirect: "follow",
      }),
      invalidatesTags: ["Mentors"],
    }),
    searchCourse: build.query({
      query: ({ name, category, role }) => ({
        url:
          name != false
            ? `courses/search?name=${name}`
            : category != false && category != "all"
            ? `courses/${category}`
            : `${role}/course`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Courses"),
    }),
    getCourseSubscribers: build.query({
      query: (id) => ({
        url: `getAllCourseEnrolledUsers/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Courses"),
    }),
    filterCourses: build.query({
      query: ({ id }) => ({
        // url: `courses/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Courses"),
    }),
  }),
});
export const {
  useGetCoursesQuery,
  useLazyGetCoursesQuery,
  useAddCourseMutation,
  useDeleteCourseMutation,
  useGetCourseByIdQuery,
  useEditCourseMutation,
  useLazyGetCourseMentorsQuery,
  useAddMentorsToCourseMutation,
  useGetCourseMentorsQuery,
  useLazySearchCourseQuery,
  useGetCourseSubscribersQuery,
  useFilterCoursesQuery,
} = coursesApi;
