import { Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditCategoryDataValue,
  setCategoriesPopUpValues,
  setCategoriesValues,
} from "../../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import {
  useAddCategoryMutation,
  useEditCategoryMutation,
} from "../../../../../api/Admin/topicCategoriesApi";
import { topicsApi } from "../../../../../api/Admin/topicsApi";
import CustomFormModal from "../../../../Independents/CustomFormModal";

const EditCategoryModal = () => {
  const [editCategory, { isLoading }] = useEditCategoryMutation();
  const {
    popUps: { editModal },
    editCategoryData: { name, time, id },
    editCategoryData,
  } = useSelector((store) => store.categoriesAdmin);
  const dispatch = useDispatch();

  const closeEditCategoryModal = () =>
    dispatch(setCategoriesPopUpValues({ key: "editModal", value: false }));

  const handleEditCategory = async () => {
    try {
      let body = {
        name,
        time,
      };
      await editCategory({ id: id, body: body }).unwrap();
      closeEditCategoryModal();
      dispatch(
        setCategoriesValues({
          key: "editCategoryData",
          value: {
            name: "",
            time: 0,
            id: "",
          },
        })
      );
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CustomFormModal
      open={editModal}
      onClose={closeEditCategoryModal}
      onSave={handleEditCategory}
      title={"Категория"}
      saveLoading={isLoading}
      disableSaveButton={name.trim() === "" || time == 0}
    >
      <Grid container spacing={"20px"}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            label="Название"
            size="medium"
            fullWidth
            value={name}
            onChange={(e) =>
              dispatch(
                setEditCategoryDataValue({ key: "name", value: e.target.value })
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            size="medium"
            type="number"
            fullWidth
            inputProps={{ min: "0", max: "999" }}
            value={time}
            onChange={(e) =>
              dispatch(
                setEditCategoryDataValue({ key: "time", value: e.target.value })
              )
            }
          />
        </Grid>
      </Grid>
    </CustomFormModal>
  );
};

export default EditCategoryModal;
