import { createSlice } from "@reduxjs/toolkit";

const StudentJournalSlice = createSlice({
  name: "studentJournal",
  initialState: {
    popUps: {
      drawer: false,
    },
  },
  reducers: {
    setJournalValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setJournalPopUPValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const { setJournalValues, setJournalPopUPValues } =
StudentJournalSlice.actions;

export default StudentJournalSlice.reducer;
