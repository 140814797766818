import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";
export const studentTopicsApi = createApi({
  reducerPath: "studentTopicsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Topics"],
  endpoints: (build) => ({
    getCourseTopics: build.query({
      query: ({ id, role }) => ({
        url: `${role}/course/${id}/topics/v1`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data?.topics, "Topics"),
    }),
    getCourseTopicsStudy: build.query({
      query: (id) => ({
        url: `student/course/${id}/topics/v1`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Topics"),
    }),
    getCourseTopicsCategoriesLessons: build.query({
      query: ({ id, role }) => ({
        url: `${role}-course/topics-categories-lessons/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data?.topics, "Topics"),
    }),
    getCourseTopicsCategoriesLessonsStudy: build.query({
      query: (id) => ({
        url: `student-course/topics-categories-lessons/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data?.topics, "Topics"),
    }),
  }),
});
export const {
  useGetCourseTopicsQuery,
  useGetCourseTopicsStudyQuery,
  useGetCourseTopicsCategoriesLessonsQuery,
  useGetCourseTopicsCategoriesLessonsStudyQuery,
} = studentTopicsApi;
