import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DeleteOutline, EditOutlined, Reorder } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { lessonIcons } from "../../../../Constants/Lessons";
import {
  setLessonsPopUpValues,
  setLessonsValues,
} from "../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { formatDuration } from "../../../../utils/TimeFormats";
import { setTopicsValues } from "../../../../Services/Admin/Course/Topics/TopicsSlice";

export default function SortableLesson(props) {
  const { id, lesson, onClick, categoryId } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box ref={setNodeRef} sx={style}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px",
          // transition: "0.25s"
        }}
        // className="hoverEffect"
        onClick={() => {
          dispatch(setTopicsValues({ key: "accordionId", value: lesson?.topic_id }));
          navigate(`/admin/courses/lesson/${lesson.id}`)
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Reorder
              {...attributes}
              {...listeners}
              sx={{
                color: "textSecondColor",
                fontSize: "16px",
                cursor: "move",
              }}
            />

            {lessonIcons[lesson?.type]}
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {lesson.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              lineHeight: "20px",
              mr: "24px",
            }}
          >
            {lesson.type !== "text" &&
              lesson.type !== "quiz" &&
              formatDuration(Math.trunc(lesson?.duration))}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setLessonsValues({
                  key: "editLessonData",
                  value: {
                    topic_category_id: categoryId,
                    id: lesson.id,
                    type: lesson.type,
                    name: lesson.name,
                    cover: lesson.cover,
                    description:
                      lesson.type === "text"
                        ? lesson.content
                        : lesson.type === "quiz" ||
                          lesson.type === "think_exam_quiz"
                          ? lesson.description
                          : "",
                    video: lesson.type === "video" ? lesson.content : "",
                    quiz: lesson.type === "quiz" ? lesson.content : "",
                    thinkExam:
                      lesson.type === "think_exam_quiz" ? lesson.content : "",
                  },
                })
              );
              dispatch(
                setLessonsPopUpValues({
                  key: "editModal",
                  value: true,
                })
              );
            }}
            color="primary"
            size="small"
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            color="error"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setLessonsValues({ key: "lesson", value: lesson }));
              dispatch(
                setLessonsPopUpValues({
                  key: "deleteModal",
                  value: true,
                })
              );
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
