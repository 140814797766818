import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";
import { topicsApi } from "./topicsApi";
// import GlobalBaseQuery, { providesList } from "";

export const topicCategoriesApi = createApi({
  reducerPath: "topicCategoriesApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Categories"],
  endpoints: (build) => ({
    getTopicsCategory: build.query({
      query: () => ({
        url: `admin/course-topic-category`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Categories"),
    }),
    getCategoryById: build.query({
      query: (id) => ({
        url: `admin/course-topic-category/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Topics"),
    }),
    addCategory: build.mutation({
      query: (body) => ({
        url: `admin/course-topic-category`,
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        url: `admin/course-topic-category/${id}`,
        method: "DELETE",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Categories"],
    }),
    editCategory: build.mutation({
      query: ({ id, body }) => ({
        url: `admin/course-topic-category/${id}`,
        method: "PUT",
        body: body,
        headers: { accept: "application/json" },
      }),
      invalidatesTags: ["Categories"],
    }),
    transferLessonsToCategory: build.mutation({
      query: (body) => ({
        url: `admin/course-topic-category/attach-lessons-to-topic-category`,
        method: "POST",
        body: body,
        headers: { accept: "application/json" },
      }),
      invalidatesTags: ["Categories"],
    }),
    reorderCategories: build.mutation({
      query: ({ body }) => ({
        url: `admin/course-topic-category/change-order`,
        method: "POST",
        body: body,
        redirect: "follow",
        accept: "application/json",
      }),
      async onQueryStarted(
        { newOrder, courseId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          topicsApi.util.updateQueryData(
            "getCourseTopics",
            courseId,
            (draft) => {
              draft.course_topics_lessons.topics = newOrder;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
  }),
});

export const {
  useAddCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useTransferLessonsToCategoryMutation,
  useReorderCategoriesMutation
} = topicCategoriesApi;
