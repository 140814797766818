import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoriesPopUpValues,
  setCategoriesValues,
} from "../../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import { topicsApi } from "../../../../../api/Admin/topicsApi";
// import { useDeleteCategoryMutation } from "../../../../../api/categoriesApi";
import CustomDeleteModal from "../../../../Independents/СustomDeleteModal";
import { useDeleteCategoryMutation } from "../../../../../api/Admin/topicCategoriesApi";

const DeleteCategoryModal = () => {
  const [deleteCategory, { isLoading }] = useDeleteCategoryMutation();
  const {
    popUps: { deleteModal },
    category,
  } = useSelector((store) => store.categoriesAdmin);
  const dispatch = useDispatch();

  const closeDeletCategoryModal = () =>
    dispatch(setCategoriesPopUpValues({ key: "deleteModal", value: false }));
  const handleDeleteСategory = async () => {
    try {
      await deleteCategory(category?.id).unwrap();
      closeDeletCategoryModal();
      dispatch(setCategoriesValues({ key: "category", value: {} }));
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CustomDeleteModal
      open={deleteModal}
      title={"Вы действительно хотите удалить эту категорию?"}
      onClose={closeDeletCategoryModal}
      onDelete={handleDeleteСategory}
      deleteLoading={isLoading}
    />
  );
};

export default DeleteCategoryModal;
