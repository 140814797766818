import React from "react";
import ReactQuill from "react-quill";
import { setAddLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { useDispatch, useSelector } from "react-redux";

const AddTextType = () => {
  const dispatch = useDispatch();
  const {
    addLessonData: { description, max_point },
  } = useSelector((store) => store.lessonsAdmin);
  return (
    <div>
      <ReactQuill
        value={description}
        onChange={(val) =>
          dispatch(setAddLessonDataValue({ key: "description", value: val }))
        }
        style={{ marginBottom: "14px" }}
      />
    </div>
  );
};

export default AddTextType;
