import { Box, Button, MenuItem, TextField } from "@mui/material";
import { setAddLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLazyGetCourseTestsQuery } from "../../../../../../api/Admin/lessonsApi";

const AddThinkExamType = ({ tests = [], courseName }) => {
  const dispatch = useDispatch();
  const {
    addLessonData: { name, thinkExam, description },
  } = useSelector((store) => store.lessonsAdmin);

  const [trigger] = useLazyGetCourseTestsQuery()
  const handleClick = () => {
    trigger(courseName)
  }
  return (
    <div>
      <Box
        sx={{
          mb: "14px",
          mt: "20px",
        }}
      >
        <TextField
          sx={{ width: "100%", height: "56px", mb: "14px" }}
          id="outlined-basic"
          label="Название"
          variant="outlined"
          name="name"
          value={name}
          onChange={(e) =>
            dispatch(
              setAddLessonDataValue({ key: "name", value: e.target.value })
            )
          }
        />
        <Box
          sx={{
            bgcolor: "#F8F9FB",
            display: "flex",
            mb: "14px", p: "16px",
            gap: "20px", borderRadius: "12px",
          }}
        >
          <TextField
            select
            fullWidth
            variant="outlined"
            sx={{ bgcolor: "#FFF" }}
            label="Выберите тест"
            value={thinkExam}
            // defaultValue={"all"}
            onChange={(e) => {
              dispatch(
                setAddLessonDataValue({
                  key: "thinkExam",
                  value: e.target.value,
                })
              );
            }}
          >
            {tests.length === 0 ? (
              <MenuItem>Пусто</MenuItem>
            ) : (
              tests.map((test) => {
                return (
                  <MenuItem key={test.test_id} value={JSON.stringify(test)}>
                    {test.testName}
                  </MenuItem>
                );
              })
            )}
          </TextField>
          <Button
            variant="outlined"
            onClick={handleClick}
          >
            <RefreshIcon sx={{ fontSize: "30px" }} />
          </Button>
        </Box>
        <ReactQuill
          value={description}
          onChange={(val) =>
            dispatch(setAddLessonDataValue({ key: "description", value: val }))
          }
        />
      </Box>
    </div>
  );
};

export default AddThinkExamType;
