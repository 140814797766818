import React, { useState } from "react";
import CustomFormModal from "../../../../Independents/CustomFormModal";
import { useDispatch, useSelector } from "react-redux";
import { setCategoriesPopUpValues } from "../../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import { lessonsApi, useGetCourseLessonsQuery } from "../../../../../api/Admin/lessonsApi";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTransferLessonsToCategoryMutation } from "../../../../../api/Admin/topicCategoriesApi";
import { topicsApi } from "../../../../../api/Admin/topicsApi";

const TransferLessonToCategoryModal = () => {
  const { id } = useParams();
  const { data } = useGetCourseLessonsQuery(id);
  const [transferLessonsToCategory, { isLoading }] =
    useTransferLessonsToCategoryMutation();
  const {
    popUps: { transferModal },
    category,
  } = useSelector((store) => store.categoriesAdmin);
  const [lessons, setLessons] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLessons(typeof value === "string" ? value.split(",") : value);
  };

  const closeTransferModal = () =>
    dispatch(setCategoriesPopUpValues({ key: "transferModal", value: false }));

  const handleTransferLessonsToCategory = async () => {
    try {
      let body = {
        lessons_ids: lessons?.map((lesson) => lesson?.lesson_id),
        topic_category_id: category?.id,
      };
      await transferLessonsToCategory(body).unwrap();
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
      dispatch(lessonsApi.util.invalidateTags(["Lessons"]));

      setLessons([]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomFormModal
      title={"Трансфер"}
      open={transferModal}
      onClose={closeTransferModal}
      onSave={handleTransferLessonsToCategory}
      saveLoading={isLoading}
      disableSaveButton={lessons.length <= 0}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-name-label">Выберите уроки</InputLabel>
        <Select
          variant="outlined"
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          // fullWidth
          // label="Выберите уроки"
          multiple
          value={lessons}
          onChange={handleChange}
          input={<OutlinedInput label="Выберите уроки" />}
        >
          {data?.course_lessons
            ?.filter(
              (lesson) => lesson?.lesson_topic_category_id !== category?.id
            )
            .map((lesson) => (
              <MenuItem key={lesson} value={lesson}>
                {lesson?.lesson_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </CustomFormModal>
  );
};

export default TransferLessonToCategoryModal;
