import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import { setEditLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

const EditThinkExamType = ({ tests = [] }) => {
  const dispatch = useDispatch();
  const {
    editLessonData: { name, thinkExam, description },
  } = useSelector((store) => store.lessonsAdmin);
  return (
    <>
      <Box
        sx={{
          mb: "14px",
          mt: "20px",
        }}
      >
        <TextField
          sx={{ width: "100%", height: "56px", mb: "14px" }}
          id="outlined-basic"
          label="Название"
          variant="outlined"
          name="name"
          value={name}
          onChange={(e) =>
            dispatch(
              setEditLessonDataValue({ key: "name", value: e.target.value })
            )
          }
        />
         <TextField
          select
          fullWidth
          variant="outlined"
          sx={{ mb: "14px" }}
          label="Выберите тест"
          value={ typeof thinkExam === "string" ?  JSON.parse(thinkExam)?.test_id : thinkExam?.test_id}
          onChange={(val) => {
            const selectedTest = tests.find(test => test.test_id === val.target.value);
            dispatch(
              setEditLessonDataValue({
                key: "thinkExam",
                value: selectedTest,
              })
            );
          }}
        >
          {tests.length === 0 ? (
            <MenuItem value="">Пусто</MenuItem>
          ) : (
            tests.map((test) => {
              return (
                <MenuItem key={test.test_id} value={test.test_id}>
                  {test.testName}
                </MenuItem>
              );
            })
          )}
        </TextField>
        <ReactQuill
          value={description}
          onChange={(val) =>
            dispatch(setEditLessonDataValue({ key: "description", value: val }))
          }
        />
      </Box>
    </>
  );
};

export default EditThinkExamType;
