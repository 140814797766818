import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { destroyToken } from "../utils/axiosRequest";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { setAuthValues } from "../Services/Auth/AuthSlice";
export function providesList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds?.map(({ id }) => {
          return { type: tagType, id };
        }),
      ]
    : [{ type: tagType, id: "LIST" }];
}
const BaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    let token = localStorage.getItem("token");
    // let role = localStorage.getItem("role");
    // let access_token=localStorage.getItem("access_token");
    headers.set("Accept", "application/json");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const GlobalBaseQuery = async (args, api, extraOptions) => {
  let result = await BaseQuery(args, api, extraOptions);

  try {
    if (
      result?.meta?.request.method !== "GET" &&
      result?.error?.status !== 401 &&
      result?.error
    ) {
      toast.error(
        // result?.meta.request.method !== "GET" &&
        result?.error?.data?.message || "Error"
      );
      return result;
    }
    if (result.error && result.error.status == 401) {
      destroyToken();
      localStorage.removeItem("token");
      api?.dispatch(setAuthValues({key:"isAuthenticated",value:false}))
      api?.dispatch(setAuthValues({key:"user",value:{}}))
      return result;
    }
    if (result?.meta.request.method !== "GET" && result?.meta.response.ok) {
      toast.success(result?.data?.message || "Success");
    }
    return result;
  } catch (error) {
    if (
      result?.meta?.request.method !== "GET" &&
      result?.error?.status !== 401
    ) {
      toast.error(
        // result?.meta.request.method !== "GET" &&
        result?.error?.data?.message || "Error"
      );
      return result;
    }
    if (result.error && result.error.status == 401) {
      destroyToken();
      api?.dispatch(setAuthValues({key:"isAuthenticated",value:false}))
      api?.dispatch(setAuthValues({key:"user",value:{}}))
      localStorage.removeItem("token");
      return result;
    }
  }
};

export default GlobalBaseQuery;
