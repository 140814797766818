import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setEditData } from "../reducers/editMentor";

export const mentorsApi = createApi({
  reducerPath: "mentorsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Mentors"],
  endpoints: (build) => ({
    getMentors: build.query({
      query: ({ page, search }) => ({
        url: `getTeachers?page=${page}&&search=${search}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Mentors"),
    }),
    getMentorById: build.query({
      query: (id) => `user/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          if (data) {
            dispatch(setEditData(data.data.user));
          }
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ["Mentors"],
    }),
    // addCourse: build.mutation({
    //   query: (body) => ({
    //     url: `course`,
    //     method: "POST",
    //     redirect: "follow",
    //     body,
    //     headers:{'Accept':'application/json'}
    //     // responseHandler: (response) =>response.message()
    //   }),
    //   invalidatesTags: ["Courses"],
    // }),
    // deleteCourse: build.mutation({
    //   query: (id) => ({
    //     url: `course/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Courses"],
    // }),
    editMentor: build.mutation({
      query: ({ id, body }) => ({
        url: `admin/user/${id}`,
        method: "POST",
        body: body,
        redirect: "follow",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Mentors"],
    }),
    blockUnblockMentor: build.mutation({
      query: (id) => ({
        url: `user/${id}/block`,
        method: "PUT",
        redirect: "follow",
      }),
      invalidatesTags: ["Mentors"],
    }),
  }),
});
export const {
  useGetMentorsQuery,
  useGetMentorByIdQuery,
  useEditMentorMutation,
  useBlockUnblockMentorMutation,
} = mentorsApi;
