import React from 'react'
import style from './Loader.module.css'
import logo from '../../../assets/Logo_blue.svg'

const Loader = ({height}) => {
  return (
    <div style={{height:height != undefined ? `${height}px` : ""}} className={`${style.loaderContainer}`}>
        <span className={style.loader}></span>
        {/* <img src={logo} alt="" className={style.pulsate} style={{width:"272px",height:"100px"}} /> */}
    </div>
  )
}

export default Loader