import { createSlice } from "@reduxjs/toolkit";

const CategoriesSlice = createSlice({
  name: "categories",
  initialState: {
    popUps: {
      addModal: false,
      deleteModal: false,
      editModal: false,
      transferModal: false,
    },
    addCategoryData: {
      topic_id: "",
      name: "",
      time: 0,
    },
    editCategoryData: {
      name: "",
      time: 0,
      id: "",
    },
    category: {},
  },
  reducers: {
    setCategoriesValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setCategoriesPopUpValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
    setAddCategoryDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.addCategoryData[key] = value;
    },
    setEditCategoryDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.editCategoryData[key] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setCategoriesValues,
  setCategoriesPopUpValues,
  setAddCategoryDataValue,
  setEditCategoryDataValue,
} = CategoriesSlice.actions;

export default CategoriesSlice.reducer;
