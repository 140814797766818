import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { useEffect } from "react";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Categories"],
  endpoints: (build) => ({
    getCategories: build.query({
      query: () => ({
        url: `categories`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result, "Categories"),
    }),
    addCategory: build.mutation({
      query: (body) => ({
        url: `category`,
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory:build.mutation({
        query:(id)=>({
          url:`category/${id}`,
          method: "DELETE",
          headers: { Accept: "application/json" },
        }),
        invalidatesTags: ["Categories"],
      }),
      editCategory:build.mutation({
        query:({id,body})=>({
          url:`category/${id}`,
          method:"PUT",
          body:body,
          headers: { Accept: "application/json" },
        }),
        invalidatesTags: ["Categories"],
      })
  }),
});
export const { useGetCategoriesQuery,useAddCategoryMutation ,useDeleteCategoryMutation ,useEditCategoryMutation } = categoriesApi;
