import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import EditLessonModal from "../../../../components/Admin/Courses/Topics/Lessons/EditLesson/EditLessonModal";
import { useGetCourseTopicsQuery } from "../../../../api/Admin/topicsApi";
import AddTopicModal from "../../../../components/Admin/Courses/Topics/AddTopicModal";
import AddCategoryModal from "../../../../components/Admin/Courses/Topics/Categories/AddCategoryModal";
import DeleteCategoryModal from "../../../../components/Admin/Courses/Topics/Categories/DeleteCategoryModal";
import EditCategoryModal from "../../../../components/Admin/Courses/Topics/Categories/EditCategoryModal";
import TransferLessonToCategoryModal from "../../../../components/Admin/Courses/Topics/Categories/TransferLessonToCategoryModal";
import DeleteTopicModal from "../../../../components/Admin/Courses/Topics/DeleteTopicModal";
import EditTopicModal from "../../../../components/Admin/Courses/Topics/EditTopicModal";
import AddLessonModal from "../../../../components/Admin/Courses/Topics/Lessons/AddLesson/AddLessonModal";
import DeleteLessonModal from "../../../../components/Admin/Courses/Topics/Lessons/DeleteLessonModal";
import TopicsSection from "../../../../components/Admin/Courses/Topics/TopicsSection";
import Loader from "../../../../components/Admin/Loader/Loader";
import { useGetCourseTestsQuery } from "../../../../api/Admin/lessonsApi";
const Topics = () => {
  const { id } = useParams();
  const { data, isLoading: topicsLoading } = useGetCourseTopicsQuery(id);
  let courseName = data?.course_topics_lessons?.course_external_id;
  const { data: tests } = useGetCourseTestsQuery(
    data?.course_topics_lessons?.course_external_id,
    { skip: !data }
  );

  if (topicsLoading == true) {
    return <Loader />;
  }
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "normal",
                sm: "start",
                md: "space-between",
              },
              alignItems: { xs: "normal", sm: "start", md: "center" },
              mb: "40px",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: { xs: "20px", sm: "20px", md: "0px" },
            }}
          >
            <Link to="/admin/courses/" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <ArrowBackIcon color="primary" />
                Курсы / {data?.course_topics_lessons?.course_name} / Программа
                курca
              </Typography>
            </Link>
          </Box>
          <TopicsSection topics={data?.course_topics_lessons?.topics} />
        </Container>
      </Box>
      <AddTopicModal courseId={id} />
      <DeleteTopicModal />
      <EditTopicModal />
      <AddLessonModal tests={tests?.group_tests} courseName={courseName} />
      {/* <AddLessonModal tests={tests?.group_tests} /> */}
      <EditLessonModal tests={tests?.group_tests} courseName={courseName} />
      <DeleteLessonModal />
      <AddCategoryModal />
      <EditCategoryModal />
      <DeleteCategoryModal />
      <TransferLessonToCategoryModal />
    </>
  );
};

export default Topics;
