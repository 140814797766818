import React, { forwardRef } from "react";
import { Box, Button, Modal, Slide, Slider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slider direction="up" ref={ref} {...props} />;
});

const СustomDeleteModal = ({
  open,
  onClose,
  onDelete,
  title,
  deleteLoading,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: "320px",
            p: "20px 20px 12px 20px",
            backgroundColor: "white",
            borderRadius: "12px",
            position: "absolute",
          }}
        >
          <Typography>{title}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              mt: "20px",
            }}
          >
            <LoadingButton
              variant="text"
              onClick={onDelete}
              color="error"
              loading={deleteLoading}
            >
              УДАЛИТЬ
            </LoadingButton>
            <Button variant="text" onClick={onClose}>
              ОТМЕНА
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default СustomDeleteModal;
