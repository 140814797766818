import { createSlice } from "@reduxjs/toolkit";

const TopicsSlice = createSlice({
  name: "topics",
  initialState: {
    popUps: {
      addModal: false,
      deleteModal: false,
      editModal: false,
    },
    addTopicData: {
      topic_name: "",
    },
    topic: {},
    editTopicData: {},
    accordionId: "",
  },
  reducers: {
    setTopicsValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setTopicsPopUpValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
    setAddTopicDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.addTopicData[key] = value;
    },
    setEditTopicDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.editTopicData[key] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setTopicsValues,
  setTopicsPopUpValues,
  setAddTopicDataValue,
  setEditTopicDataValue,
} = TopicsSlice.actions;

export default TopicsSlice.reducer;
