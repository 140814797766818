import { createSlice } from "@reduxjs/toolkit";

const ApplicationSlice = createSlice({
    name: "application",
    initialState: {
        modal: false,
        applicationId: null,
    },
    reducers: {
        setModalValue: (state, action) => {
            const { key, value } = action.payload
            state[key] = value
        },
        setApplicationId: (state, action) => {
            const { key, value } = action.payload
            state[key] = value
        }
    },
    extraReducers: builder => { },
})

export const {
    setModalValue,
    setApplicationId
} = ApplicationSlice.actions

export default ApplicationSlice.reducer