import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const axiosLogin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },  
  (error) => {
    return Promise.reject(error);
  }
);

function saveToken(access_token) {
  localStorage.setItem("token", access_token);
}
function getToken() {
  try {
    if (localStorage.getItem("token")) {
      return jwt_decode(localStorage.getItem("token"));
    }
  } catch (error) {
    console.log(error);
  }
}
function destroyToken() {
  localStorage?.removeItem("token");
  localStorage?.removeItem("isLogged");
  localStorage?.removeItem("access_token");
  localStorage?.removeItem("role");
}
export { axiosLogin, axiosRequest, saveToken, destroyToken, getToken };
