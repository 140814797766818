import { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import {
  Account,
  AddNewCourseAdmin,
  AuthGmail,
  CertificateSinglePage,
  Certificates,
  Contacts,
  CourseSinglePage,
  Courses,
  CoursesAdmin,
  DashboardAdmin,
  EditCourseAdmin,
  EditMentorAdmin,
  Home,
  Journal,
  JournalAdmin,
  Layout,
  LayoutAdmin,
  Login,
  MentorsAdmin,
  MyLearning,
  NotFound,
  Notifications,
  Profile,
  Purchases,
  Register,
  Settings,
  SkillsAdmin,
  StudentJournalPageAdmin,
  StudentsAdmin,
  StudentsApplicationsAdmin,
  Study,
  SubscribersAdmin,
  SubscriptionsAdmin,
  SuperAdmin,
  TopicsAdmin,
  UsersAdmin,
  ViewLessonsAdmin,
  Wallet,
  ZabonOmuz
} from "./routes/routes";
import AuthCheck from "./utils/AuthCheck";
import ProtectRoute from "./utils/ProtectedRoute";
import ProtectAdminRoute from "./utils/ProtectedAdminRoute";
import Loader from "./components/Admin/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop";
import Subscribers from "./pages/Admin/Subscribers/Subscribers";
import { Toaster } from "react-hot-toast";
import { getToken } from "./utils/axiosRequest";
import { useDispatch, useSelector } from "react-redux";
import { GetMe } from "./Services/Auth/AuthService";
import { roles } from "./Constants/Roles";
import { useGetMeQuery } from "./api/authApi";

function App() {
  const { data, isLoading } = useGetMeQuery(roles[getToken()?.user_type], {
    skip: !localStorage.getItem("token") ? true : false,
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <Home />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          />
          <Route
            path="contacts"
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <Contacts />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          />
          <Route
            path="zabonomuz"
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <ZabonOmuz />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          />
          <Route
            path="mylearning"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectRoute validRoles={["student"]}>
                  <MyLearning />
                </ProtectRoute>
              </Suspense>
            }
          />
          <Route
            path="courses"
            element={
              <Suspense fallback={<Loader />}>
                <Courses />
              </Suspense>
            }
          />

          <Route
            path="courses/:id"
            element={
              <Suspense fallback={<Loader />}>
                <CourseSinglePage />
              </Suspense>
            }
          />

          <Route
            path="study/:id/:lessonUrlId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectRoute validRoles={["student"]}>
                  <Study />
                </ProtectRoute>
              </Suspense>
            }
          />
          <Route
            path="journal/:id"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectRoute validRoles={["student"]}>
                  <Journal />
                </ProtectRoute>
              </Suspense>
            }
          />
          {/* <Route
          path="certificates/:id"
          element={
            <Suspense fallback={<Loader/>}>
              <CertificateSinglePage />
            </Suspense>
          }
        /> */}
          <Route
            path="auth-gmail"
            element={
              <Suspense fallback={<Loader />}>
                <AuthCheck>
                  <AuthGmail />
                </AuthCheck>
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<Loader />}>
                <AuthCheck>
                  <Login />
                </AuthCheck>
              </Suspense>
            }
          />
          <Route
            path="register"
            element={
              <Suspense fallback={<Loader />}>
                <AuthCheck>
                  <Register />
                </AuthCheck>
              </Suspense>
            }
          />
          <Route
            path="account"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectRoute validRoles={["student"]}>
                  <Account />
                </ProtectRoute>
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectRoute validRoles={["student"]}>
                    <Profile />
                  </ProtectRoute>
                </Suspense>
              }
            />
            <Route
              path="purchases"
              element={
                <Suspense fallback={<Loader />}>
                  <Purchases />
                </Suspense>
              }
            />
            {/* <Route
            path="certificates"
            element={
              <Suspense fallback={<Loader/>}>
                <Certificates />
              </Suspense>
            }
          /> */}
            {/* <Route
          path="notifications"
          element={
            <Suspense fallback={<Loader/>}>
              <Notifications />
            </Suspense>
          }
        /> */}
            <Route
              path="settings"
              element={
                <Suspense fallback={<Loader />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              path="wallet"
              element={
                <Suspense fallback={<Loader />}>
                  <Wallet />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/admin"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectRoute validRoles={["admin"]}>
                <LayoutAdmin />
              </ProtectRoute>
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <DashboardAdmin />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          ></Route>
          <Route
            path="/admin/courses"
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <CoursesAdmin />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          />
          <Route
            path="/admin/students_applications"
            element={
              <Suspense fallback={<Loader />}>
                {/* <ProtectRoute> */}
                <StudentsApplicationsAdmin />
                {/* </ProtectRoute> */}
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/addNewCourse"
            element={
              <Suspense fallback={<Loader />}>
                <AddNewCourseAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/edit/:id"
            element={
              <Suspense fallback={<Loader />}>
                <EditCourseAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/:id/topics"
            element={
              <Suspense fallback={<Loader />}>
                <TopicsAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/:id/subscriptions"
            element={
              <Suspense fallback={<Loader />}>
                <SubscriptionsAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/:id/skills"
            element={
              <Suspense fallback={<Loader />}>
                <SkillsAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/:id/journal"
            element={
              <Suspense fallback={<Loader />}>
                <JournalAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/:id/journal/:studentId"
            element={
              <Suspense fallback={<Loader />}>
                <StudentJournalPageAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/students"
            element={
              <Suspense fallback={<Loader />}>
                <StudentsAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/mentors"
            element={
              <Suspense fallback={<Loader />}>
                <MentorsAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/mentors/edit/:id"
            element={
              <Suspense fallback={<Loader />}>
                <EditMentorAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/subscribers"
            element={
              <Suspense fallback={<Loader />}>
                <SubscribersAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/users"
            element={
              <Suspense fallback={<Loader />}>
                <UsersAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/superadmin"
            element={
              <Suspense fallback={<Loader />}>
                <SuperAdmin />
              </Suspense>
            }
          />
          <Route
            path="/admin/courses/lesson/:id"
            element={
              <Suspense fallback={<Loader />}>
                <ViewLessonsAdmin />
              </Suspense>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster
        position="bottom-right"
        containerStyle={{
          zIndex: 9999999,
        }}
        reverseOrder={false}
        toastOptions={{ style: { zIndex: 9999999 } }}
      />
    </>
  );
}

export default App;
