import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setTopicsPopUpValues } from "../../../../Services/Admin/Course/Topics/TopicsSlice";
import TopicsAccordion from "./TopicsAccordion";

const TopicsSection = ({ topics = [] }) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ mt: "40px" }}>
      <Box>
        {topics?.length > 0 &&
          topics?.map((topic) => {
            return (
              <TopicsAccordion
                topics={topics}
                key={topic.id}
                topic={topic}
                id={topic.id}
              />
            );
          })}
      </Box>
      <Button
        variant="outlined"
        onClick={() =>
          dispatch(setTopicsPopUpValues({ key: "addModal", value: true }))
        }
        startIcon={<AddCircleOutline />}
        size="large"
        sx={{ mt: "40px" }}
      >
        Новый топик
      </Button>
    </Box>
  );
};

export default TopicsSection;
