import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "editCourse",
  initialState: {
    editData: {
      name: "",
      slug: "",
      short_description: "",
      quantity_lessons: "",
      hours_lessons: "",
      logo: "",
      video: "",
      has_certificate: "",
      category_id:null,
      type_id:0

    },
  },
  reducers: {
    handleChangeCourse(state, action) {
      const { name, value } = action.payload;
      state.editData[name] = value;
    },
    setEditData(state, action) {
      const data = action.payload;
      state.editData.name = data.data.name;
      state.editData.slug = data.data.slug;
      state.editData.short_description = data.data.short_description;
      state.editData.quantity_lessons = data.data.quantity_lessons;
      state.editData.hours_lessons = data.data.hours_lessons;
      state.editData.logo = data.data.logo;
      state.editData.video = data.data.video;
      state.editData.has_certificate = data.data.has_certificate;
      state.editData.category_id = data.data.category_id;
      state.editData.type_id = data.data.type_id;
    },
  },
});

export const { handleChangeCourse, setEditData } = slice.actions;

export default slice.reducer;