import { TextField } from "@mui/material";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { setAddLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";

const AddQuizType = () => {
  const {
    addLessonData: { name, quiz, description, max_point },
  } = useSelector((store) => store.lessonsAdmin);
  const dispatch = useDispatch();
  return (
    <div>
      <>
        <TextField
          sx={{
            width: "100%",
            height: "56px",
            mb: "14px",
            mt: "20px",
          }}
          id="outlined-basic"
          label="Название"
          variant="outlined"
          name="link"
          value={name}
          onChange={(e) =>
            dispatch(
              setAddLessonDataValue({ key: "name", value: e.target.value })
            )
          }
        />
        <TextField
          sx={{
            width: "100%",
            height: "56px",
            mb: "14px",
          }}
          id="outlined-basic"
          label="Ссылка на задание"
          variant="outlined"
          name="link"
          value={quiz}
          onChange={(e) =>
            dispatch(
              setAddLessonDataValue({ key: "quiz", value: e.target.value })
            )
          }
        />
        <ReactQuill
          value={description}
          onChange={(val) =>
            dispatch(setAddLessonDataValue({ key: "description", value: val }))
          }
          style={{ marginBottom: "14px" }}
        />
        <TextField
          type="number"
          fullWidth
          label="Максимальный балл"
          value={max_point}
          variant="outlined"
          onChange={(e) => {
            dispatch(
              setAddLessonDataValue({ key: "max_point", value: e.target.value })
            );
          }}
        />
      </>
    </div>
  );
};

export default AddQuizType;
