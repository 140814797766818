import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "../api/loginApi";
import { coursesApi } from "../api/coursesApi";
// import { topicsApi } from "../api/topicsApi";
// import { lessonsApi } from "../api/lessonsApi";
import { skillsApi } from "../api/skillsApi";
import { subscriptionsApi } from "../api/subscriptionsApi";
import editUser from "../reducers/editUser";
import editCourse from "../reducers/editCourse";
import { studentsApi } from "../api/studentsApi";
import { mentorsApi } from "../api/mentorsApi";
import editMentor from "../reducers/editMentor";
import { balanceApi } from "../api/balanceApi";
import purchases from "../reducers/purchases";
import { usersApi } from "../api/usersApi";
import { statisticsApi } from "../api/statisticsApi";
import { categoriesApi } from "../api/categoriesApi";
import { ordersApi } from "../api/ordersApi";
import LayoutSlice from "../Services/Student/Layout/LayoutSlice";
import AuthSlice from "../Services/Auth/AuthSlice";
import JournalSlice from "../Services/Admin/Course/Journal/JournalSlice";
import StudentJournalSlice from "../Services/Student/Course/Journal/JournalSlice";
import Topics from "../pages/Admin/Courses/Topics/Topics";
import TopicsSlice from "../Services/Admin/Course/Topics/TopicsSlice";
import { topicsApi } from "../api/Admin/topicsApi";
import LessonsSlice from "../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { lessonsApi } from "../api/Admin/lessonsApi";
import CategoriesSlice from "../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import { topicCategoriesApi } from "../api/Admin/topicCategoriesApi";
import StudySlice from "../Services/Student/Study/StudySlice";
import {studentTopicsApi } from "../api/Student/topicsApi";
import { studyApi } from "../api/Student/studyApi";
import { studentAccountApi } from "../api/Student/accountApi";
import { authApi } from "../api/authApi";
import { journalApi } from "../api/Admin/journalApi";
import { applicationApi } from "../api/Admin/applications";
import ApplicationSlice from "../Services/Admin/Course/Application/ApplicationSlice";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    [lessonsApi.reducerPath]: lessonsApi.reducer,
    [skillsApi.reducerPath]: skillsApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [studentsApi.reducerPath]: studentsApi.reducer,
    [mentorsApi.reducerPath]: mentorsApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [topicCategoriesApi.reducerPath]: topicCategoriesApi.reducer,
    [studentTopicsApi.reducerPath]: studentTopicsApi.reducer,
    [studyApi.reducerPath]: studyApi.reducer,
    [studentAccountApi.reducerPath]: studentAccountApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [journalApi.reducerPath]: journalApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    editUser: editUser,
    editCourse: editCourse,
    editMentor: editMentor,
    purchases: purchases,
    layoutStudent: LayoutSlice,
    auth: AuthSlice,
    journal: JournalSlice,
    journalStudent: StudentJournalSlice,
    application: ApplicationSlice,
    topicsAdmin: TopicsSlice,
    lessonsAdmin: LessonsSlice,
    categoriesAdmin: CategoriesSlice,
    studyStudent: StudySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      coursesApi.middleware,
      topicsApi.middleware,
      lessonsApi.middleware,
      skillsApi.middleware,
      subscriptionsApi.middleware,
      studentsApi.middleware,
      mentorsApi.middleware,
      balanceApi.middleware,
      usersApi.middleware,
      statisticsApi.middleware,
      categoriesApi.middleware,
      ordersApi.middleware,
      topicCategoriesApi.middleware,
      studentTopicsApi.middleware,
      studyApi.middleware,
      studentAccountApi.middleware,
      authApi.middleware,
      journalApi.middleware,
      applicationApi.middleware
    ),
});
