import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";
// import { setEditData } from "../reducers/editUser";
// import { destroyToken } from "../utils/axiosRequest";
// import { balanceApi } from "./balanceApi";

export const studentAccountApi = createApi({
  reducerPath: "studentAccountApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Account", "Balance"],
  endpoints: (build) => ({
    getMe: build.query({
      query: () => ({
        url: `student/account`,
        headers: { Accept: "application/json" },
      }),
      //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      //     try {
      //       const data = await queryFulfilled;
      //       if (data.data[1] == 200) {
      //         dispatch(setEditData(data.data));
      //       }
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   },
      providesTags: (result) => {
        try {
          return providesList(result?.data, "Account");
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getBalance: build.query({
      query: () => ({
        url: `student/balances`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Balance"),
    }),
  }),
});

export const { useGetMeQuery, useGetBalanceQuery } = studentAccountApi;
