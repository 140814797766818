import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setAuthValues } from "../Services/Auth/AuthSlice";
// import { setEditData } from "../reducers/editUser";
// import { destroyToken } from "../utils/axiosRequest";
// import { balanceApi } from "./balanceApi";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["auth"],
  endpoints: (build) => ({
    getMe: build.query({
      query: (role) => ({
        url: `${role}/account`,
        headers: { Accept: "application/json" },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          if (data.data[1] == 200) {
            dispatch(setAuthValues({ key: "isAuthenticated", value: true }));
            dispatch(setAuthValues({ key: "user", value: data.data[0] }));
          }
        } catch (error) {
          // localStorage.removeItem("token");
          console.log(error);
        }
      },
      providesTags: (result) => providesList(result?.data || result, "auth"),
    }),
  }),
});

export const { useGetMeQuery } = authApi;
