import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setEditData } from "../reducers/editCourse";

export const statisticsApi = createApi({
  reducerPath: "statisticsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Statistics"],
  endpoints: (build) => ({
    getStatistics: build.query({
      query: () => ({
        url: `getStatistics`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Statistics"),
    }),
    getStatisticsByYear: build.query({
      query: (year) => ({
        url: `months/${year}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Statistics"),
    }),
  }),
  
});
export const {
    useGetStatisticsQuery,useGetStatisticsByYearQuery
} = statisticsApi;
