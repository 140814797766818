import { TextField } from "@mui/material";
import React from "react";
import { setEditLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { useDispatch, useSelector } from "react-redux";

const EditVideoType = () => {
  const dispatch = useDispatch();
  const {
    editLessonData: { video },
  } = useSelector((store) => store.lessonsAdmin);
  return (
    <>
      <TextField
        sx={{ width: "100%", height: "56px", mb: "14px" }}
        id="outlined-basic"
        label="Видео"
        variant="outlined"
        value={video}
        onChange={(e) =>
          dispatch(
            setEditLessonDataValue({ key: "video", value: e.target.value })
          )
        }
      />
    </>
  );
};

export default EditVideoType;
