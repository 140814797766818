import { createSlice } from "@reduxjs/toolkit";

const LessonsSlice = createSlice({
  name: "lessons",
  initialState: {
    popUps: {
      addModal: false,
      deleteModal: false,
      editModal: false,
    },
    addLessonData: {
      topic_id: "",
      topic_category_id: "",
      type: "text",
      name: "",
      cover: "",
      video: "",
      quiz: "",
      thinkExam: "",
      description: "",
      max_point: 0
    },
    editLessonData: {
      topic_category_id: "",
      id: "",
      type: "text",
      name: "",
      cover: "",
      description: "",
      video: "",
      quiz: "",
      thinkExam: "",
    },
    lesson: {},
  },
  reducers: {
    setLessonsValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setLessonsPopUpValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
    setAddLessonDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.addLessonData[key] = value;
    },
    setEditLessonDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.editLessonData[key] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setLessonsValues,
  setLessonsPopUpValues,
  setAddLessonDataValue,
  setEditLessonDataValue,
} = LessonsSlice.actions;

export default LessonsSlice.reducer;
