import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import GlobalBaseQuery, { providesList } from './api'
import { useEffect } from 'react'


export const skillsApi = createApi({
    reducerPath:"skillsApi",
    baseQuery:GlobalBaseQuery,
    tagTypes:['Skills'],
    endpoints:(build)=>({
        getCourseSkills:build.query({
            query:({id,role})=>`${role}/course/${id}/skill`,
            providesTags:(result)=>providesList(result,'Skills'),
            headers:{'Accept':'application/json'}
        }),
        addSkill:build.mutation({
            query:(body)=>({
                url:`skill`,
                method: 'POST',
                redirect:'follow',
                body
            }),
            invalidatesTags: ["Skills"],
        }),
        deleteSkill:build.mutation({
            query:(id)=>({
              url:`skill/${id}`,
              method: "DELETE",
            }),
            invalidatesTags: ["Skills"],
          }),
          editSkill:build.mutation({
            query:({id,body})=>({
              url:`skill/${id}`,
              method:"POST",
              body:body
            }),
            invalidatesTags: ["Skills"],
          })
    })
})
export const {useGetCourseSkillsQuery ,useAddSkillMutation,useDeleteSkillMutation ,useEditSkillMutation}=skillsApi