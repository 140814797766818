import React, { useRef } from "react";
import { CloudUploadOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const FileInput = ({
  file,
  setFile,
  accept = "",
  defaultValue = "",
  alertMessage = "",
  sx = {},
  label = "",
}) => {
  const ref = useRef(null);
  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => ref.current.click()}>
                <CloudUploadOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={sx}
        fullWidth
        size="medium"
        label={label}
        variant="outlined"
        value={typeof file === "object" ? file?.name : file || defaultValue}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files[0].type.startsWith(accept)) {
            setFile(e.dataTransfer.files[0]);
          } else {
            alert(alertMessage);
          }
        }}
      />
      <input
        type="file"
        style={{ display: "none" }}
        id="file"
        ref={ref}
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
        accept={accept === "application/pdf" ? accept : `${accept}/*`}
      />
    </>
  );
};

export default FileInput;
