import {
  ArticleOutlined,
  OndemandVideoOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";

export const lessonIcons = {
  video: (
    <OndemandVideoOutlined
      sx={{
        fontSize: "16px",
        color: "#323232",
      }}
    />
  ),
  quiz: (
    <TaskAltOutlined
      sx={{
        fontSize: "16px",
        color: "#323232",
      }}
    />
  ),
  text: (
    <ArticleOutlined
      sx={{
        fontSize: "16px",
        color: "#323232",
      }}
    />
  ),
  think_exam_quiz: (
    <TaskAltOutlined
      sx={{
        fontSize: "16px",
        color: "#323232",
      }}
    />
  ),
};
