import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Subscriptions"],
  endpoints: (build) => ({
    getCourseSubscriptions: build.query({
      query: ({ slug, role }) => ({
        url: `${role}/course/${slug}/subscriptions`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result, "Subscriptions"),
      headers: { Accept: "application/json" },
    }),

    addSubscription: build.mutation({
      query: (body) => ({
        url: `subscription`,
        method: "POST",
        redirect: "follow",
        body,
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    deleteSubscription: build.mutation({
      query: (id) => ({
        url: `subscription/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    editSubscription: build.mutation({
      query: ({ id, body }) => ({
        url: `subscription/${id}`,
        method: "PUT",
        body,
        redirect: "follow",
      }),
      invalidatesTags: ["Subscriptions"],
    }),
  }),
});

export const {
  useGetCourseSubscriptionsQuery,
  useAddSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useEditSubscriptionMutation,
} = subscriptionsApi;
