import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  AddCircleOutline,
  Close,
  EditOutlined,
  ExpandMore,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCategoryDataValue,
  setCategoriesPopUpValues,
} from "../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import {
  setTopicsPopUpValues,
  setTopicsValues,
} from "../../../../Services/Admin/Course/Topics/TopicsSlice";
import SortableCategory from "./SortableCategory";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { useParams } from "react-router-dom";
import { useReorderCategoriesMutation } from "../../../../api/Admin/topicCategoriesApi";

const TopicsAccordion = ({ topic, id, topics = [] }) => {
  const { id: courseId } = useParams();
  const [reorderCategories] = useReorderCategoriesMutation();
  const dispatch = useDispatch();
  const openEditTopicModal = (e) => {
    e.stopPropagation();
    dispatch(setTopicsValues({ key: "topic", value: topic }));
    dispatch(setTopicsValues({ key: "editTopicData", value: topic }));
    dispatch(
      setTopicsPopUpValues({
        key: "editModal",
        value: true,
      })
    );
  };

  const openDeleteTopicModal = (e) => {
    e.stopPropagation();
    dispatch(
      setTopicsPopUpValues({
        key: "deleteModal",
        value: true,
      })
    );
    dispatch(
      setTopicsValues({
        key: "topic",
        value: topic,
      })
    );
  };
  const openAddCategoryModal = (e) => {
    e.stopPropagation();
    dispatch(setAddCategoryDataValue({ key: "topic_id", value: topic?.id }));
    dispatch(setCategoriesPopUpValues({ key: "addModal", value: true }));
  };
  const getTopicIndexFromCategoryId = (categoryId) => {
    // Iterate through the topics array
    for (let i = 0; i < topics.length; i++) {
      const topic = topics[i];
      // Check if the lesson with the given ID exists in the current topic's lessons array
      const categoryIndex = topic.categories.findIndex(
        (category) => category.id === categoryId
      );
      if (categoryIndex !== -1) {
        // If the lesson is found, return the index of the current topic
        return i;
      }
    }
    // If the lesson is not found in any topic, return -1 or handle the case accordingly
    return -1;
  };
  const handleDragCategoryEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }
    const topicIndex = getTopicIndexFromCategoryId(active.id);
    const value = () => {
      const oldIndex = topics[topicIndex].categories.findIndex(
        (elem) => elem.id == active.id
      );
      const newIndex = topics[topicIndex].categories.findIndex(
        (elem) => elem.id == over.id
      );

      return arrayMove(topics[topicIndex].categories, oldIndex, newIndex);
    };
    const updatedDatas = topics.map((topic, index) => {
      if (index === topicIndex) {
        return {
          ...topic,
          categories: value(),
        };
      }
      return topic;
    });
    let currentIndex = value().findIndex((category) => category.id === active.id);
    let currentCategory = value()[currentIndex];
    let nextLesson =
      currentIndex < value().length - 1 ? value()[currentIndex + 1] : null;
    let prevLesson = currentIndex > 0 ? value()[currentIndex - 1] : null;
    reorderCategories({
      body: {
        topic_category_id: currentCategory?.id,
        old_next_id: currentCategory?.next_id,
        old_prev_id: currentCategory?.prev_id,
        new_next_id: nextLesson?.id || 0,
        new_prev_id: prevLesson?.id || 0,
      },
      newOrder: updatedDatas,
      courseId: courseId,
    });
  };
  const accordionId = useSelector((state) => state.topicsAdmin.accordionId);
  return (
    <>

      <Accordion
        
          sx={{
            boxShadow: "none",
            paddingY: "4px",
          }}
          disableGutters
        className="adminTopics"
        defaultExpanded={accordionId === id}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: "26px" }} />}
            sx={{ borderRadius: "10px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  width: "100%",
                }}
              >
                {/* <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  outline: "none",
                }}
              >
                <ArrowUpward sx={{ color: "#A3A3A3" }} />
                <ArrowDownward sx={{ color: "#A3A3A3" }} />
              </Box> */}

                <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    {topic?.topic_name}
                  </Typography>
                  <IconButton
                    onClick={openEditTopicModal}
                    size="small"
                    color="primary"
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>
              <IconButton size="small" onClick={openDeleteTopicModal}>
                <Close />
              </IconButton>
            </Box>
          </AccordionSummary>
          <Box sx={{ padding: "0px 28px 28px 28px" }}>
            <DndContext
              // sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragCategoryEnd}
            >
              <SortableContext
                id={id}
                items={topic?.categories}
                strategy={verticalListSortingStrategy}
              >
                {topic?.categories?.length > 0 ? (
                  topic.categories.map((category) => {
                    return (
                      <SortableCategory
                        key={category.id}
                        category={category}
                        topic={topic}
                        id={category.id}
                        topics={topics}
                      />
                    );
                  })
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    Категории не найдены
                  </Typography>
                )}
              </SortableContext>
            </DndContext>
          </Box>
          <AccordionDetails sx={{ borderTop: "1px solid #E5E7EB" }}>
            <Button
              onClick={openAddCategoryModal}
              variant="text"
              startIcon={<AddCircleOutline />}
              size="large"
            >
              Добавить категорию
            </Button>
          </AccordionDetails>
        </Accordion>
    </>
  );
};
export default TopicsAccordion;
