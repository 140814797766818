import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "editUser",
  initialState: {
    editData: {
      id: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      gender: "",
      city: "",
      date_of_birth: "",
      photo: "",
      photoFile:"",
      constName:"",
      constSurname:"",
      constEmail:"",
      constPhone:"",
      constPhoto:"",
      constGender:"",
      constCity:"",
      constDateOfBirth:"",
      // confirmCode:""
    }
  },
  reducers: {
    handleChangeUser(state, action) {
      const { name, value } = action.payload;
      state.editData[name] = value;
    },
    handleChangeConfirmCode(state, action) {
      // const { name, value } = action.payload;
      // state.editData.confirmCode = action.payload;
    },
    setEditData(state, action) {
      const data = action.payload;
      state.editData.id = data[0].id;
      state.editData.name = data[0].name;
      state.editData.surname = data[0].surname;
      state.editData.email = data[0].email;
      state.editData.phone = data[0].phone;
      state.editData.gender = data[0].gender;
      state.editData.city = data[0].city;
      state.editData.date_of_birth = data[0].date_of_birth;
      state.editData.photo = data[0].photo;
      state.editData.constName = data[0].name;
      state.editData.constSurname = data[0].surname;
      state.editData.constEmail = data[0].email;
      state.editData.constPhone = data[0].phone;
      state.editData.constPhoto = data[0].photo;
      state.editData.constGender = data[0].gender;
      state.editData.constCity = data[0].city;
      state.editData.constDateOfBirth = data[0].date_of_birth;

      
    },
    // setToDefaultValue(state) {
    //   state.editData.name ="";
    //   state.editData.surname = "";
    //   state.editData.email = "";
    //   state.editData.phone = "";
    //   state.editData.gender = "";
    //   state.editData.city = "";
    //   state.editData.date_of_birth = "";
    //   state.editData.photo = "";
    // },
  },
});

export const { handleChangeUser, setEditData,setToDefaultValue,handleChangeConfirmCode } = slice.actions;
export default slice.reducer;