import ReactQuill from "react-quill";
import { setEditLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { useDispatch, useSelector } from "react-redux";

const EditTextType = () => {
  const dispatch = useDispatch();
  const {
    editLessonData: { description },
  } = useSelector((store) => store.lessonsAdmin);
  return (
    <div>
      <ReactQuill
        value={description}
        onChange={(val) =>
          dispatch(setEditLessonDataValue({ key: "description", value: val }))
        }
      />
    </div>
  );
};

export default EditTextType;
