import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setEditData } from "../reducers/editUser";
import { destroyToken } from "../utils/axiosRequest";
import { balanceApi } from "./balanceApi";
import { setAuthValues } from "../Services/Auth/AuthSlice";
import { studentTopicsApi } from "./Student/topicsApi";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Login"],
  endpoints: (build) => ({
    accountData: build.query({
      query: (role) => ({
        url: `${role}/account`,
        headers: { Accept: "application/json" },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          if (data.data[1] == 200) {
            dispatch(setAuthValues({ key: "isAuthenticated", value: true }));
            dispatch(setAuthValues({ key: "user", value: data.data[0] }));
            dispatch(setEditData(data.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: (result) => {
        try {
          return providesList(result?.data, "Login");
        } catch (error) {
          console.log(error);
        }
      },
    }),
    login: build.mutation({
      query: (body) => ({
        url: "login",
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      onQueryStarted(api) {},
      invalidatesTags: ["Login"],
    }),
    register: build.mutation({
      query: (body) => ({
        url: "register",
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
    logout: build.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
        redirect: "follow",
        headers: { Accept: "application/json" },
      }),
      onQueryStarted: async (arg, { dispatch,queryFulfilled}) => {
        try {
          // Correct way to invalidate the 'Purchase' tag
          await queryFulfilled
          await dispatch(balanceApi.util.resetApiState());
          dispatch(setAuthValues({ key: "user", value: {} }));
          dispatch(setAuthValues({ key: "isAuthenticated", value: false }));
    
          await dispatch(studentTopicsApi.util.resetApiState())
        } catch (error) {
          console.log(error);
        }
      },
    }),
    verifyCode: build.mutation({
      query: (body) => ({
        url: "/verify-phone",
        method: "POST",
        redirect: "follow",
        body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
    editUser: build.mutation({
      query: (body) => ({
        url: `student/profile`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
    sendResetCode: build.mutation({
      query: (body) => ({
        url: `password/forgot`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: `password/reset`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: `change/password`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      // invalidatesTags: ["Login"]
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useAccountDataQuery,
  useGetTokenQuery,
  useLazyAccountDataQuery,
  useEditUserMutation,
  useVerifyCodeMutation,
  useSendResetCodeMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = loginApi;
