import { TextField } from "@mui/material";
import React from "react";
import { setEditLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

const EditQuizType = () => {
  const dispatch = useDispatch();
  const {
    editLessonData: { name, quiz, description },
  } = useSelector((store) => store.lessonsAdmin);
  return (
    <>
      <TextField
        sx={{
          width: "100%",
          height: "56px",
          mb: "14px",
          mt: "20px",
        }}
        id="outlined-basic"
        label="Название"
        variant="outlined"
        name="link"
        value={name}
        onChange={(e) =>
          dispatch(
            setEditLessonDataValue({ key: "name", value: e.target.value })
          )
        }
      />
      <TextField
        sx={{
          width: "100%",
          height: "56px",
          mb: "14px",
        }}
        id="outlined-basic"
        label="Ссылка на задание"
        variant="outlined"
        name="link"
        value={quiz}
        onChange={(e) =>
          dispatch(
            setEditLessonDataValue({ key: "quiz", value: e.target.value })
          )
        }
      />
      <ReactQuill
        value={description}
        onChange={(val) =>
          dispatch(setEditLessonDataValue({ key: "description", value: val }))
        }
      />
    </>
  );
};

export default EditQuizType;
