import { createSlice } from "@reduxjs/toolkit";
import { GetMe } from "./AuthService";
import { getToken } from "../../utils/axiosRequest";
// import { destroyToken, getToken, saveToken } from "src/Utils/Token";

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    popUps: {
      logOutModal: false,
    },
    user: {},
    status: "idle",
    error: null,
    isAuthenticated: false,
    getMeStatus: "idle",
    // logOutLoading: false,
    // updateProfileData: {
    //   name: "",
    //   surname: "",
    //   date_of_birth: "",
    //   email: "",
    //   phone: "",
    //   photo: "",
    //   photoUrl: "",
    // },
    // updateProfileLoading: false,
    // updatePassword: {
    //   currentPassword: "",
    //   newPassword: "",
    //   confirmNewPassword: "",
    // },
    // updatePasswordLoading: false,
  },
  reducers: {
    setAuthValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setAuthPopUpValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
    // setUpdateProfileValues: (state, action) => {
    //   const { key, value } = action.payload;
    //   state.updateProfileData[key] = value;
    // },
    // setUpdatePasswordValues: (state, action) => {
    //   const { key, value } = action.payload;
    //   state.updatePassword[key] = value;
    // },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(AuthService.Login.pending, (state) => {
      //   state.status = "loading";
      //   state.error = null;
      // })
      // .addCase(AuthService.Login.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   saveToken(action.payload.access_token);
      //   state.isAuthenticated = getToken()?.user_type === 1;
      //   state.isValidLogin = false;
      //   state.isValidPassword = false;
      // })
      // .addCase(AuthService.Login.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      // })
      .addCase(GetMe.pending, (state) => {
        state.getMeStatus = "loading";
      })
      .addCase(GetMe.fulfilled, (state, action) => {
        state.getMeStatus = "succeeded";
        state.user = { ...action.payload[0], user_type: getToken()?.user_type };
        state.isAuthenticated = true;
        // state.updateProfileData = {
        //   name: action.payload[0]?.name,
        //   surname: action.payload[0]?.surname,
        //   date_of_birth: action.payload[0]?.date_of_birth,
        //   email: action.payload[0]?.email,
        //   phone: action.payload[0]?.phone,
        //   photo: action.payload[0]?.photo || "",
        //   photoUrl: `${import.meta.env.VITE_APP_ASSETS_URL}/storage/${
        //     action.payload[0]?.photo
        //   }`,
        // };
      })
      .addCase(GetMe.rejected, (state, action) => {
        state.getMeStatus = "failed";
        state.error = action.payload;
        state.isAuthenticated = false;
      });
    // .addCase(AuthService.Logout.pending, (state) => {
    //   state.logOutLoading = true;
    // })
    // .addCase(AuthService.Logout.fulfilled, (state, action) => {
    //   state.popUps.logOutModal = false;
    //   state.logOutLoading = false;
    //   destroyToken();
    //   window.location = "/login";
    // })
    // .addCase(AuthService.Logout.rejected, (state, action) => {
    //   state.logOutLoading = false;
    //   state.error = action.payload;
    // })
    // .addCase(AuthService.UpdatePassword.pending, (state) => {
    //   state.updatePasswordLoading = true;
    // })
    // .addCase(AuthService.UpdatePassword.fulfilled, (state, action) => {
    //   state.updatePasswordLoading = false;
    //   state.updatePassword = {
    //     currentPassword: "",
    //     newPassword: "",
    //     confirmNewPassword: "",
    //   };
    // })
    // .addCase(AuthService.UpdatePassword.rejected, (state, action) => {
    //   state.updatePasswordLoading = false;
    //   state.error = action.payload;
    // })
    // .addCase(AuthService.UpdateProfile.pending, (state) => {
    //   state.updateProfileLoading = true;
    // })
    // .addCase(AuthService.UpdateProfile.fulfilled, (state, action) => {
    //   state.updateProfileLoading = false;
    // })
    // .addCase(AuthService.UpdateProfile.rejected, (state, action) => {
    //   state.updateProfileLoading = false;
    //   state.error = action.payload;
    // });
  },
});

export const {
  setAuthValues,
  setAuthPopUpValues,
  // setUpdateProfileValues,
  // setUpdatePasswordValues,
} = AuthSlice.actions;

export default AuthSlice.reducer;
