import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Orders"],
  endpoints: (build) => ({
    getOrders: build.query({
      query: () => ({
        url: `application-course`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result, "Orders"),
      headers: { Accept: "application/json" },
    }),

    addOrder: build.mutation({
      query: (body) => ({
        url: `application-course`,
        method: "POST",
        redirect: "follow",
        headers: { Accept: "application/json" },
        body,
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const { useAddOrderMutation } = ordersApi;
