import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "../api";

export const applicationApi = createApi({
    reducerPath: "applicationApi",
    baseQuery: GlobalBaseQuery,
    tagTypes: ["Application"],
    endpoints: (build) => ({
        getApplications: build.query({
            query: () => ({
                url: `applications`,
                headers: { Accept: "application/json" },
            }),
            providesTags: result => providesList(result?.data, "Application") 
        }),
        deleteApplication: build.mutation({
            query: (applicationId) => ({
                url: `application/${applicationId}`,
                method: "DELETE",
                headers: { Accept: "application/json" },
            }),
            invalidatesTags: ["Application"],
        })
    }),
});
export const { useGetApplicationsQuery, useDeleteApplicationMutation } = applicationApi;