import { createSlice } from "@reduxjs/toolkit";

const StudySlice = createSlice({
  name: "study",
  initialState: {
    popUps: {
      extraTimeModal: false,
    },
    homeWorkData: {
      file: "",
      link: "",
      description: "",
      type:"link"
    },
    // editLessonData: {
    //   topic_category_id: "",
    //   id: "",
    //   type: "text",
    //   name: "",
    //   cover: "",
    //   description: "",
    //   video: "",
    //   quiz: "",
    // },
    // lesson: {},
  },
  reducers: {
    setStudyValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setStudyPopUpValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
    setHomeWorkDataValue: (state, action) => {
      const { key, value } = action.payload;
      state.homeWorkData[key] = value;
    },
    // setEditLessonDataValue: (state, action) => {
    //   const { key, value } = action.payload;
    //   state.editLessonData[key] = value;
    // },
  },
  extraReducers: (builder) => {},
});

export const { setStudyValues, setStudyPopUpValues ,setHomeWorkDataValue } = StudySlice.actions;

export default StudySlice.reducer;
