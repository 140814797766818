import { Box, TextField } from "@mui/material";
import React from "react";
import { setEditLessonDataValue } from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import FileInput from "../../../../../Independents/FileInput";
import { useDispatch, useSelector } from "react-redux";

const EditDefaultType = () => {
  const dispatch = useDispatch();
  const {
    editLessonData: { name, cover },
  } = useSelector((store) => store.lessonsAdmin);

  return (
    <>
      <Box sx={{ mt: "20px" }}>
        <TextField
          sx={{ width: "100%", height: "56px", mb: "14px" }}
          id="outlined-basic"
          label="Название"
          variant="outlined"
          name="name"
          value={name}
          onChange={(e) =>
            dispatch(
              setEditLessonDataValue({ key: "name", value: e.target.value })
            )
          }
        />
      </Box>
      <FileInput
        file={cover}
        setFile={(file) =>
          dispatch(setEditLessonDataValue({ key: "cover", value: file }))
        }
        accept="image"
        defaultValue=".jpeg / .png / .jpg / .svg"
        alertMessage="Разрешены только файлы изображений."
        label="Обложка"
        sx={{ mb: "14px" }}
      />
    </>
  );
};

export default EditDefaultType;
