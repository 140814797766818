import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../utils/axiosRequest";

// export const Login = createAsyncThunk(
//   "auth/login",
//   async (body, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosAPI.post("/login", {
//         ...body,
//         _method: "POST",
//       });
//       return data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response?.data?.message);
//     }
//   }
// );

// export const Logout = createAsyncThunk(
//   "auth/logout",
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosAPI.post("/logout");
//       return data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response?.data?.message);
//     }
//   }
// );
export const GetMe = createAsyncThunk(
  "auth/getMe",
  async (role, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(`/${role}/account`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
// export const UpdatePassword = createAsyncThunk(
//   "auth/updatePassword",
//   async (body, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosAPI.post("/change/password", body);
//       return data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response?.data?.message);
//     }
//   }
// );
// export const UpdateProfile = createAsyncThunk(
//   "auth/updateProfile",
//   async (body, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosAPI.post("admin/profile", body);
//       return data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response?.data?.message);
//     }
//   }
// );
