import { useSelector } from "react-redux";
import { getToken } from "./axiosRequest";
import { Navigate } from "react-router-dom";

const AuthCheck = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated && getToken()?.user_type === 3) {
    return <Navigate to={"/"} replace />;
  }
  if (isAuthenticated && getToken()?.user_type === 1) {
    return <Navigate to={"/admin/"} replace />;
  }
  return children;
};
export default AuthCheck;
