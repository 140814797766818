import React, { forwardRef } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomFormModal = ({
  children,
  open,
  onSave,
  onClose,
  title,
  saveLoading,
  maxWidth = "xs",
  disableSaveButton = false,
}) => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={maxWidth}
        scroll="paper"
        fullWidth={true}
        fullScreen={fullScreen}
      >
        <DialogTitle
          sx={{
            padding: "18px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="responsive-dialog-title"
        >
          <Typography
            // variant="h2"
            color={"#262626"}
            fontWeight={"600"}
            fontSize={"18px"}
            lineHeight={"24px"}
          >
            {title}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="24px" />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ padding: "20px" }}>
          <form
            id="form"
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
            }}
          >
            {children}
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <LoadingButton
            variant="contained"
            size="medium"
            autoFocus
            type="submit"
            form="form"
            loading={saveLoading}
            disabled={disableSaveButton}
          >
            Сохранить
          </LoadingButton>
          <Button variant="outlined" size="medium" onClick={onClose} autoFocus>
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomFormModal;
