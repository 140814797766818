import { TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditTopicDataValue,
  setTopicsPopUpValues,
  setTopicsValues,
} from "../../../../Services/Admin/Course/Topics/TopicsSlice";
import { useEditTopicMutation } from "../../../../api/Admin/topicsApi";
import CustomFormModal from "../../../Independents/CustomFormModal";

const EditTopicModal = ({ courseId }) => {
  const [editTopic, { isLoading }] = useEditTopicMutation();
  const {
    popUps: { editModal },
    editTopicData: { topic_name },
    topic,
    editTopicData,
  } = useSelector((store) => store.topicsAdmin);
  const dispatch = useDispatch();

  const closeEditTopicModal = () =>
    dispatch(setTopicsPopUpValues({ key: "editModal", value: false }));

  const handleEditTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("course_id", courseId);
      formData.append("topic_name", topic_name);
      await editTopic({
        id: editTopicData.id,
        body: editTopicData,
      }).unwrap();
      closeEditTopicModal();
      dispatch(
        setTopicsValues({
          key: "editTopicData",
          value: {},
        })
      );
      dispatch(
        setTopicsValues({
          key: "topic",
          value: {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomFormModal
      title={"Изменить топик"}
      maxWidth="xs"
      open={editModal}
      onClose={closeEditTopicModal}
      onSave={handleEditTopic}
      saveLoading={isLoading}
      disableSaveButton={topic_name === topic.topic_name}
    >
      <TextField
        variant="outlined"
        size="medium"
        label="Название"
        fullWidth
        value={topic_name}
        onChange={(e) =>
          dispatch(
            setEditTopicDataValue({ key: "topic_name", value: e.target.value })
          )
        }
      />
    </CustomFormModal>
  );
};

export default EditTopicModal;