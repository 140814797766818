import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import {
  setEditLessonDataValue,
  setLessonsPopUpValues,
  setLessonsValues,
} from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import CustomFormModal from "../../../../../Independents/CustomFormModal";
import { useEditLessonMutation } from "../../../../../../api/Admin/lessonsApi";
import { topicsApi } from "../../../../../../api/Admin/topicsApi";
import EditDefaultType from "./EditDefaultType";
import EditTextType from "./EditTextType";
import EditVideoType from "./EditVideoType";
import EditQuizType from "./EditQuizType";
import EditThinkExamType from "./EditThinkExamType";

const EditLessonModal = ({ tests = [] }) => {
  const [editLesson, { isLoading }] = useEditLessonMutation();
  const {
    popUps: { editModal },
    editLessonData: {
      topic_category_id,
      id,
      type,
      name,
      cover,
      description,
      video,
      quiz,
      thinkExam,
    },
  } = useSelector((store) => store.lessonsAdmin);
  const dispatch = useDispatch();

  const closeEditLessonModal = () =>
    dispatch(setLessonsPopUpValues({ key: "editModal", value: false }));

  const handleEditLesson = async () => {
    try {
      const formData = new FormData();
      formData.append("topic_category_id", topic_category_id);
      formData.append("name", name);
      formData.append("type", type);
      if (type !== "quiz" && type !== "think_exam_quiz") {
        if (typeof cover !== "string") {
          formData.append("cover", cover, cover?.name);
        }
      }
      if (type === "quiz" || type === "think_exam_quiz") {
        formData.append("description", description);
      }
      formData.append(
        "content",
        type === "video"
          ? video
          : type === "quiz"
          ? quiz
          : type === "text"
          ? description
          : type === "think_exam_quiz"
          ?  typeof thinkExam  === "string" ? thinkExam : JSON.stringify(thinkExam)
          : null
      );
      formData.append("_method", "PUT");
      await editLesson({ id: id, body: formData }).unwrap();
      closeEditLessonModal();
      dispatch(
        setLessonsValues({
          key: "editLessonData",
          value: {
            topic_category_id: "",
            id: "",
            type: "text",
            name: "",
            cover: "",
            description: "",
            video: "",
            quiz: "",
            thinkExam: "",
          },
        })
      );
      dispatch(topicsApi.util.invalidateTags(["Topics"]));

      //   dispatch(balanceApi.util.invalidateTags(["Purchase"]));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CustomFormModal
      open={editModal}
      onClose={closeEditLessonModal}
      title={"Редактирование"}
      maxWidth="md"
      onSave={handleEditLesson}
      saveLoading={isLoading}
      disableSaveButton={
        (type === "text" && (cover === "" || description === "")) || // Disable if type is text and cover is not an object or description is empty
        (type === "video" && (video === "" || cover === "")) || // Disable if type is video and either video or cover is not an object
        (type === "quiz" && (quiz === "" || description === "")) || // Disable if type is quiz and either quiz or description is empty
        name.trim() === "" // Disable if name is empty or contains only whitespace
      }
    >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={type}
        onChange={(e) =>
          dispatch(
            setEditLessonDataValue({ key: "type", value: e.target.value })
          )
        }
      >
        <FormControlLabel
          value="text"
          control={<Radio />}
          label="Текстовый"
          componentsProps={{ typography: { fontWeight: "500" } }}
          disabled={type !== "text"}
        />
        <FormControlLabel
          value="video"
          control={<Radio />}
          label="Видео"
          componentsProps={{ typography: { fontWeight: "500" } }}
          disabled={type !== "video"}
        />

        <FormControlLabel
          value="quiz"
          control={<Radio />}
          label="Quiz"
          componentsProps={{ typography: { fontWeight: "500" } }}
          disabled={type !== "quiz"}
        />
        <FormControlLabel
          value="think_exam_quiz"
          control={<Radio />}
          label="Think Exam"
          componentsProps={{ typography: { fontWeight: "500" } }}
          disabled={type !== "think_exam_quiz"}
        />
      </RadioGroup>
      {type !== "quiz" && type !== "think_exam_quiz" && <EditDefaultType />}
      {type === "text" ? (
        <EditTextType />
      ) : type === "video" ? (
        <EditVideoType />
      ) : type === "quiz" ? (
        <EditQuizType />
      ) : type === "think_exam_quiz" ? (
        <EditThinkExamType tests={tests} />
      ) : null}
    </CustomFormModal>
  );
};

export default EditLessonModal;
