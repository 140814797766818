import React from "react";
import CustomDeleteModal from "../../../Independents/СustomDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setTopicsPopUpValues,
  setTopicsValues,
} from "../../../../Services/Admin/Course/Topics/TopicsSlice";
import { useDeleteTopicMutation } from "../../../../api/Admin/topicsApi";

const DeleteTopicModal = () => {
  const [deleteTopic, { isLoading }] = useDeleteTopicMutation();
  const {
    popUps: { deleteModal },
    topic,
  } = useSelector((store) => store.topicsAdmin);
  const dispatch = useDispatch();

  const closeDeleteTopicModal = () =>
    dispatch(setTopicsPopUpValues({ key: "deleteModal", value: false }));

  const handleDeleteTopic = async () => {
    try {
      await deleteTopic(topic.id).unwrap();
      dispatch(
        setTopicsValues({
          key: "topic",
          value: {},
        })
      );
      closeDeleteTopicModal();
      dispatch(
        setTopicsValues({
          key: "topic",
          value: {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CustomDeleteModal
      open={deleteModal}
      title={"Вы действительно хотите удалить этот топик?"}
      onClose={closeDeleteTopicModal}
      onDelete={handleDeleteTopic}
      deleteLoading={isLoading}
    />
  );
};

export default DeleteTopicModal;
