import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLessonsPopUpValues,
  setLessonsValues,
} from "../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import { useDeleteLessonMutation } from "../../../../../api/Admin/lessonsApi";
import { topicsApi } from "../../../../../api/Admin/topicsApi";
import CustomDeleteModal from "../../../../Independents/СustomDeleteModal";

const DeleteLessonModal = () => {
  const [deleteLesson, { isLoading }] = useDeleteLessonMutation();
  const {
    popUps: { deleteModal },
    lesson,
  } = useSelector((store) => store.lessonsAdmin);
  const dispatch = useDispatch();

  const closeDeletLessonModal = () =>
    dispatch(setLessonsPopUpValues({ key: "deleteModal", value: false }));
  const handleDeleteLesson = async () => {
    try {
      await deleteLesson(lesson?.id).unwrap();
      closeDeletLessonModal();
      dispatch(setLessonsValues({ key: "lesson", value: {} }));
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomDeleteModal
      open={deleteModal}
      title={"Вы действительно хотите удалить этот урок?"}
      onClose={closeDeletLessonModal}
      onDelete={handleDeleteLesson}
      deleteLoading={isLoading}
    />
  );
};

export default DeleteLessonModal;
