import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  setCategoriesPopUpValues,
  setCategoriesValues,
} from "../../../../Services/Admin/Course/Topics/Categories/CategoriesSlice";
import { useDispatch } from "react-redux";
import {
  AddCircleOutline,
  DeleteOutline,
  EditOutlined,
  MoveDownOutlined,
  QueryBuilderOutlined,
  Reorder,
} from "@mui/icons-material";
import {
  setAddLessonDataValue,
  setLessonsPopUpValues,
} from "../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableLesson from "./SortableLesson";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { useGetCourseTopicsQuery } from "../../../../api/Admin/topicsApi";
import { useParams } from "react-router-dom";
import { useReorderLessonsMutation } from "../../../../api/Admin/lessonsApi";

const SortableCategory = ({ category, topic, id, topics = [] }) => {
  const { id: courseId } = useParams();
  const [reorderLessons] = useReorderLessonsMutation();
  const dispatch = useDispatch();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };
  const handleDragLessonEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }

    // Find the topic index and category index of the active lesson
    let topicIndex = -1;
    let categoryIndex = -1;
    for (let i = 0; i < topics?.length; i++) {
      const topic = topics[i];
      const foundCategoryIndex = topic.categories.findIndex((category) =>
        category.lessons.some((lesson) => lesson.id === active.id)
      );
      if (foundCategoryIndex !== -1) {
        topicIndex = i;
        categoryIndex = foundCategoryIndex;
        break;
      }
    }

    if (topicIndex === -1 || categoryIndex === -1) {
      // Handle case where active lesson is not found in any category
      return;
    }

    const topic = topics[topicIndex];
    const category = topic.categories[categoryIndex];

    // Get the lessons array for the active category
    const value = () => {
      const oldIndex = category.lessons.findIndex(
        (lesson) => lesson.id === active.id
      );
      const newIndex = category.lessons.findIndex(
        (lesson) => lesson.id === over.id
      );
      return arrayMove(category.lessons, oldIndex, newIndex);
    };

    // Update the lessons array for the active category
    const updatedDatas = topics?.map((t, tIndex) => {
      if (tIndex === topicIndex) {
        const updatedCategories = t.categories.map((c, cIndex) => {
          if (cIndex === categoryIndex) {
            return {
              ...c,
              lessons: value(),
            };
          }
          return c;
        });
        return {
          ...t,
          categories: updatedCategories,
        };
      }
      return t;
    });

    // Reorder the lessons
    let currentIndex = value().findIndex((lesson) => lesson.id === active.id);
    let currentLesson = value()[currentIndex];
    let nextLesson =
      currentIndex < value().length - 1 ? value()[currentIndex + 1] : null;
    let prevLesson = currentIndex > 0 ? value()[currentIndex - 1] : null;
    reorderLessons({
      body: {
        lesson_id: currentLesson?.id,
        old_next_id: currentLesson?.next_id,
        old_prev_id: currentLesson?.prev_id,
        new_next_id: nextLesson?.id || 0,
        new_prev_id: prevLesson?.id || 0,
      },
      newOrder: updatedDatas,
      courseId: courseId,
    });
  };
  return (
    <Box ref={setNodeRef} sx={style}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: "14px",
        }}
      >
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Reorder
            {...attributes}
            {...listeners}
            sx={{
              color: "textSecondColor",
              fontSize: "16px",
              cursor: "move",
            }}
          />
          <Typography fontWeight={"700"} color={"textColor"} fontSize={"16px"}>
            {category.name}
          </Typography>
          <IconButton
            onClick={() => {
              dispatch(
                setCategoriesValues({
                  key: "editCategoryData",
                  value: category,
                })
              );
              dispatch(
                setCategoriesPopUpValues({
                  key: "editModal",
                  value: true,
                })
              );
            }}
            size="small"
            color="primary"
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(
                setCategoriesValues({
                  key: "category",
                  value: category,
                })
              );
              dispatch(
                setCategoriesPopUpValues({
                  key: "deleteModal",
                  value: true,
                })
              );
            }}
            size="small"
            color="error"
          >
            <DeleteOutline />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button
            variant="text"
            size="small"
            startIcon={<AddCircleOutline />}
            onClick={() => {
              dispatch(
                setLessonsPopUpValues({
                  key: "addModal",
                  value: true,
                })
              );
              dispatch(
                setAddLessonDataValue({
                  key: "topic_category_id",
                  value: category.id,
                })
              );
              dispatch(
                setAddLessonDataValue({
                  key: "topic_id",
                  value: topic.id,
                })
              );
            }}
          >
            Новый урок
          </Button>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              dispatch(
                setCategoriesPopUpValues({
                  key: "transferModal",
                  value: true,
                })
              );
              dispatch(
                setCategoriesValues({
                  key: "category",
                  value: category,
                })
              );
            }}
          >
            <MoveDownOutlined />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              padding: "4px 8px 4px 4px",
              backgroundColor: "#DBEAFE",
              borderRadius: "100px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QueryBuilderOutlined color="#323232" sx={{ fontSize: "16px" }} />
            <Typography fontWeight={600} fontSize={"14px"} color={"textColor"}>
              {category.time} ч
            </Typography>
          </Box>
        </Box>
      </Box>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragLessonEnd}
      >
        <SortableContext
          id={id}
          items={category?.lessons}
          strategy={verticalListSortingStrategy}
        >
          {category?.lessons?.length > 0 ? (
            category?.lessons?.map((lesson) => {
              return (
                <SortableLesson
                  lesson={lesson}
                  id={lesson.id}
                  key={lesson.id}
                  categoryId={category?.id}
                />
              );
            })
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              Уроки не найдены
            </Typography>
          )}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default SortableCategory;
