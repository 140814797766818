import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { roles } from "../Constants/Roles";
import { getToken } from "./axiosRequest";

const ProtectRoute = ({ validRoles = [], children }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const isValidRole = isAuthenticated
    ? user?.roles?.some((role) => validRoles.includes(roles[role.id]))
    : false;

  return (isAuthenticated && isValidRole) ||
    validRoles.includes(roles[getToken()?.user_type]) ? (
    children
  ) : (
    <Navigate to={"/login"} replace />
  );
};

export default ProtectRoute;
