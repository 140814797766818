import { Search } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useGetStudentsSubscribersQuery } from "../../../api/studentsApi";
import SubscriberInfoDrawer from "../../../components/Admin/Users/SubscriberInfoDrawer";
import Loader from "../../../components/Admin/Loader/Loader";

const Subscribers = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { data = {}, isLoading } = useGetStudentsSubscribersQuery({
    page: page,
    search: search,
  });
  const [idx, setIdx] = useState(null);
  const [drawerData, setDrawerData] = useState({});
  const [infoDrawer, setInfoDrawer] = useState(false);
  if (isLoading == true) {
    return <Loader />;
  }
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
              Студенты
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setPage(1);
                setSearch(e.target["searchParam"].value);
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                //   defaultValue={".mp4 / .mpeg"}
                sx={{
                  width: { xs: "100%", sm: "300px", md: "300px" },
                  height: "56px",
                }}
                id="outlined-basic"
                label="Поиск"
                variant="outlined"
                name="searchParam"
              />
            </form>
            {/* <Link to='/admin/courses/addNewCourse'> <Button variant='contained' sx={{height:"48px"}} startIcon={<AddCircleOutline/>}>
        Новый курс
        </Button>
        </Link> */}
          </Box>
          <Box sx={{ mt: "50px" }}>
            {/* <TableContainer component={Paper}> */}
            <Table
              sx={{
                minWidth: 700,
                borderRadius: "12px",
                overflow: "hidden",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "12px", fontSize: "24px" }}>
                    №
                  </TableCell>
                  <TableCell sx={{ padding: "12px", fontSize: "24px" }}>
                    ФИО
                  </TableCell>
                  <TableCell sx={{ padding: "12px", fontSize: "24px" }}>
                    Дата
                  </TableCell>
                  <TableCell sx={{ padding: "12px", fontSize: "24px" }}>
                    Подписка
                  </TableCell>
                  <TableCell sx={{ padding: "12px", fontSize: "24px" }}>
                    Цена (сомони)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.subscriptions?.length > 0 &&
                  data?.subscriptions?.map((elem, index) => {
                    return (
                      <TableRow
                        key={elem.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setInfoDrawer(true);
                          setDrawerData(elem);
                          setIdx(1);
                        }}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "white",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                      >
                        <TableCell sx={{ padding: "12px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#262626",
                            fontWeight: "400",
                            padding: "12px",
                          }}
                        >
                          {elem.surname} {elem.name}
                        </TableCell>
                        {/* <TableCell sx={{color:"#262626",fontWeight:"400",padding:"12px"}}>{Math.trunc(elem.price)} c</TableCell> */}
                        <TableCell
                          sx={{
                            color: "#262626",
                            fontWeight: "400",
                            padding: "12px",
                          }}
                        >
                          {new Date(
                            elem?.subscription?.created_at
                          )?.toLocaleDateString("ru", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#262626",
                            fontWeight: "400",
                            padding: "12px",
                          }}
                        >
                          {elem.subscription?.name +
                            " - " +
                            elem.subscription?.duration +
                            " " +
                            elem?.subscription.duration_type}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#262626",
                            fontWeight: "400",
                            padding: "12px",
                          }}
                        >
                          {Math.trunc(elem.subscription?.price)} c
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {/* </TableContainer> */}
            {data?.subscriptions?.length == 0 &&<Typography sx={{textAlign:"center",mt:"15px"}}>Подписчики не найдены</Typography>}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "50px" }}
            >
              <Pagination
                count={Math.ceil(data?.meta.total / data?.meta.per_page)}
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </Box>
          </Box>
          <SubscriberInfoDrawer
            drawerData={drawerData}
            open={infoDrawer}
            onClose={() => {
              setInfoDrawer(false);
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default Subscribers;
