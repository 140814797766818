import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery from "../api";
export const studyApi = createApi({
  reducerPath: "studyApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    confirmCategoryExtend: build.mutation({
      query: (body) => ({
        url: `student/topic-category/prolong`,
        method: "POST",
        body: body,
        redirect: "follow",
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: [],
    }),
    getStudentThinkExamScores: build.query({
      query: ({ userId, studentLessonId }) => ({
        url: `student/think-exam/scores?user_id=${userId}&&student_lesson_id=${studentLessonId}`,
        headers: { Accept: "application/json" },
      }),
      // providesTags: (result) => providesList(result?.data, "Lessons"),
      // headers: { Accept: "application/json" },
    }),
    generateThinkExamLink: build.mutation({
      query: (body) => ({
        url: `student/think-exam/test-link`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: [""],
    }),
    sendHomeWork: build.mutation({
      query: (body) => ({
        url: `student-works/store`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: [""],
    }),
    getStudentQuizScores: build.query({
      query: (studentLessonId) => ({
        url: `student-lesson/scores-mentor-comments/${studentLessonId}`,
        headers: { Accept: "application/json" },
      }),
      // providesTags: (result) => providesList(result?.data, "Lessons"),
      // headers: { Accept: "application/json" },
    }),

  }),
});
export const {
  useConfirmCategoryExtendMutation,
  useGetStudentThinkExamScoresQuery,
  useSendHomeWorkMutation,
  useGenerateThinkExamLinkMutation,
  useGetStudentQuizScoresQuery,
} = studyApi;